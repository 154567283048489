import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../app/store';

interface AutoRefreshState {
  auto_refresh: boolean,
  refresh_interval: any,
}

const initialState: AutoRefreshState = {
  auto_refresh: true,
  refresh_interval: null,
};

export const autoRefreshSlice = createSlice({
  name: 'autoRefresh',
  initialState,
  reducers: {
    setAutoRefresh: (state, action: PayloadAction<{autoRefresh: boolean, refreshInterval: any}>) => {
      state.auto_refresh = action.payload.autoRefresh
      if (state.auto_refresh) {
        state.refresh_interval = action.payload.refreshInterval
      } else if (state.refresh_interval) {
        clearInterval(state.refresh_interval)
        state.refresh_interval = null
      }
    },
    clearRefreshInterval: (state) => {
      if (state.refresh_interval) {
        clearInterval(state.refresh_interval)
        state.refresh_interval = null
      }
    },
  },
});

export const {setAutoRefresh, clearRefreshInterval } = autoRefreshSlice.actions;

export const selectAutoRefresh = (state: RootState) => state.autoRefresh.auto_refresh
export const selectRefreshInterval = (state: RootState) => state.autoRefresh.refresh_interval


export default autoRefreshSlice.reducer;
