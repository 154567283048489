import {createSlice, PayloadAction} from '@reduxjs/toolkit';
import {AppThunk, RootState} from '../../app/store';
import {Device, Devices} from "../../domain/Device";
import {getHeaders, handleError, handleResponse} from "../../utils/fetchUtils";

interface AlarmsPayload {
  account_id: string,
  devices: Device[],
}


interface AlarmsState {
  list: Devices,
}

const initialState: AlarmsState = {
  list: {},
}

export const alarmsSlice = createSlice({
  name: 'alarms',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<AlarmsPayload>) => {
      state.list[action.payload.account_id] = action.payload.devices.filter(d => !d.confirmed_offline);
    },
    deleteAlarmsState: (state) => {
      state.list = {}
    },
  },
});

export const { setList, deleteAlarmsState } = alarmsSlice.actions

export const selectList = (state: RootState) => state.alarms.list

export const setListAsync = (accessToken: string, account_id: string): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/devices/${account_id}?alarms&offline`, {
    method: 'GET',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then((devices) => {
      dispatch(setList({account_id, devices}));
    })
    .catch((error) => handleError(dispatch, error))
}


export default alarmsSlice.reducer;
