import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import {setLoggedIn} from '../../authorization/authorizationSlice';
import {Account} from "../../../domain/Account";
import {setListAsync} from "../list/accountsSlice";
import {getHeaders, handleError, handleResponse} from "../../../utils/fetchUtils";

interface AccountState {
  obj: Account,
}

const initialState: AccountState = {
  obj: null as any
};


export const editAccountSlice = createSlice({
  name: 'account',
  initialState,
  reducers: {
    newAccount: (state) => {
      state.obj = {id: '', name: '', modified: ''}
    },
    setAccount: (state, action: PayloadAction<Account>) => {
      state.obj = action.payload;
    },
    setId: (state, action: PayloadAction<string>) => {
      state.obj.id = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.obj.name = action.payload;
    },
    deleteAccountState: (state) => {
      state.obj = null as any
    },
  },
});

export const {newAccount, setAccount, setId, setName, deleteAccountState } = editAccountSlice.actions;

export const selectAccount = (state: RootState) => state.account.obj;

export const setAccountAsync = (accessToken: string, id: string): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/accounts/${id}`, {
    method: 'GET',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then((data) => {
      dispatch(setAccount(data));
    })
    .catch((error) => handleError(dispatch, error))
}


export const createAsync = (accessToken: string, obj: Account): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/accounts/${obj.id}`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(obj)
  }).then(response => handleResponse(dispatch, response))
    .catch((error) => handleError(dispatch, error))
}


export const updateAsync = (accessToken: string, obj: Account): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/accounts/${obj.id}`, {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(obj)
  }).then(response => handleResponse(dispatch, response))
    .catch((error) => handleError(dispatch, error))
}

export const deleteAsync = (accessToken: string, id: string): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/accounts/${id}`, {
    method: 'DELETE',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then(() => dispatch(setListAsync(accessToken)))
    .catch((error) => handleError(dispatch, error))
}

export default editAccountSlice.reducer;
