import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  createAsync, deleteAccountState,
  selectAccount,
  setAccountAsync,
  setId,
  setName,
  updateAsync
} from './editAccountSlice';
import {selectAccessToken} from "../../authorization/authorizationSlice";
import {Button, Container, Form, FormGroup, Input, Label, Spinner} from "reactstrap";
import { useParams, useNavigate } from 'react-router-dom'
import {Account} from "../../../domain/Account";
import {deleteAccountsState} from "../list/accountsSlice";
import {siteTitlePrefix} from "../../../App";
import {AdminUsers} from "../../users/list/Users";

export function EditAccount() {
  const { id } = useParams()
  const obj : Account = useSelector(selectAccount)
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const accessToken = useSelector(selectAccessToken)
  const title = (id !== 'new' ? 'Edit': 'New') + ' Account'

  useEffect(() => {
    document.title = siteTitlePrefix + title
  }, []);

  useEffect(() => {
      if (id !== 'new') {
        dispatch(setAccountAsync(accessToken, id as string));
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return obj == null ? (<Spinner animation="border" className="m-5"/>) : (
    <>
      <h1>{title}</h1>
      <Form>
        <FormGroup>
          <Label for="id">Account ID</Label>
          <Input
            disabled={id !== 'new'}
            name="id"
            id="id"
            placeholder="Account ID"
            value={obj.id}
            onChange={e => dispatch(setId(e.target.value))}
          />
        </FormGroup>
        <FormGroup>
          <Label for="id">Account Name</Label>
          <Input
            name="name"
            id="name"
            placeholder="Account Name"
            value={obj.name}
            onChange={e => dispatch(setName(e.target.value))}
          />
        </FormGroup>
        <Button className="m-2"
          onClick={() => {
            dispatch(deleteAccountsState())
            id === 'new'
              ? dispatch(createAsync(accessToken, obj))
              : dispatch(updateAsync(accessToken, obj));
            navigate('/accounts')}}>
          Save
        </Button>
        <Button className="m-2"
          onClick={() => {
            dispatch(deleteAccountState())
            navigate('/accounts')
          }}>
          Cancel
        </Button>
      </Form>
      {id !== 'new' && <>
          <hr />
          <AdminUsers account_id={obj.id}/>
        </>
      }
    </>
  );
}
