import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectAccessToken} from "../authorization/authorizationSlice";
import {
  Button, Col, Form, FormGroup, Input, Label,
  Modal, ModalBody, ModalFooter, ModalHeader, Row,
} from "reactstrap";
import { useParams } from 'react-router-dom'
import {
  closePopup,
  selectPopup,
  selectClinicalNotesInput, selectDoctorList, selectDoctorUserIdInput,
  selectOtherPatientIdInput,
  selectPatientDateOfBirthInput, selectPatientGenderInput, selectPatientIdInput,
  selectPatientNameInput,
  setDoctorUserIdInput,
  setPatientDateOfBirthInput,
  setPatientGenderInput,
  setPatientNameInput, setPatientIdInput, setOtherPatientIdInput, setClinicalNotesInput
} from "./assignToPatientSlice";
import {updateDeviceAsync} from "./updateDevice";

interface AssignToPatientProps {
  onSave: any,
}

export function AssignToPatient(props: AssignToPatientProps) {
  const { account_id, device_id } = useParams() as any
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken)
  const popup = useSelector(selectPopup)
  const patientNameInput = useSelector(selectPatientNameInput)
  const patientDateOfBirthInput = useSelector(selectPatientDateOfBirthInput)
  const patientGenderInput = useSelector(selectPatientGenderInput)
  const patientIdInput = useSelector(selectPatientIdInput)
  const otherPatientIdInput = useSelector(selectOtherPatientIdInput)
  const clinicalNotesInput = useSelector(selectClinicalNotesInput)
  const doctorUserIdInput = useSelector(selectDoctorUserIdInput)
  const doctorList = useSelector(selectDoctorList)

  return (
    <Modal isOpen={popup} >
      <ModalHeader>Assign To Patient</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="patient_name">Patient name</Label>
            <Input
              name="patient_name"
              id="patient_name"
              placeholder="Patient name"
              value={patientNameInput}
              onChange={e => dispatch(setPatientNameInput(e.target.value))}
            />
          </FormGroup>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="patient_name">Date of birth</Label>
                <Input
                  type="date"
                  name="date_of_birth"
                  id="date_of_birth"
                  placeholder="Date of birth"
                  value={patientDateOfBirthInput}
                  onChange={e => dispatch(setPatientDateOfBirthInput(e.target.value))}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="patient_name">Gender</Label>
                <Input
                  type="select"
                  name="gender"
                  id="gender"
                  placeholder="Gender"
                  value={patientGenderInput}
                  onChange={e => dispatch(setPatientGenderInput(e.target.value))}
                >
                  <option value=''/>
                  <option value='female'>Female</option>
                  <option value='male'>Male</option>
                </Input>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={6}>
              <FormGroup>
                <Label for="patient_name">Patient ID</Label>
                <Input
                  name="patient_id"
                  id="patient_id"
                  placeholder="Patient ID"
                  value={patientIdInput}
                  onChange={e => dispatch(setPatientIdInput(e.target.value))}
                />
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label for="patient_name">Other patient ID</Label>
                <Input
                  name="other_patient_id"
                  id="other_patient_id"
                  placeholder="Other patient ID"
                  value={otherPatientIdInput}
                  onChange={e => dispatch(setOtherPatientIdInput(e.target.value))}
                />
              </FormGroup>
            </Col>
          </Row>
          <FormGroup>
            <Label for="patient_name">Referral doctor</Label>
            <Input
              type="select"
              name="doctor"
              id="doctor"
              placeholder="Referral doctor"
              value={doctorUserIdInput}
              onChange={e => dispatch(setDoctorUserIdInput(e.target.value))}
            >
              <option key='-1' value=''>(unassigned)</option>
              {doctorList.map((item, index) => (
                <option key={index} value={item.user_id}>{item.name}</option>
              ))}
            </Input>
          </FormGroup>
          <FormGroup>
            <Label for="patient_name">Clinical notes</Label>
            <Input
              type="textarea"
              name="clinical_notes"
              id="clinical_notes"
              placeholder="Clinical notes"
              value={clinicalNotesInput}
              onChange={e => dispatch(setClinicalNotesInput(e.target.value))}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => {
          dispatch(updateDeviceAsync(accessToken, account_id, device_id, {
            patient_name: patientNameInput,
            patient_date_of_birth: patientDateOfBirthInput,
            patient_gender: patientGenderInput,
            doctor_user_id: doctorUserIdInput,
            patient_id: patientIdInput,
            other_patient_id: otherPatientIdInput,
            clinical_notes: clinicalNotesInput,
          }))
          props.onSave()
          dispatch(closePopup())
        }}>Save</Button>
        <Button color="secondary" onClick={() => dispatch(closePopup())}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}
