import React from "react";
import styles from './DataElement.module.css';

interface DataElementProps {
  title: string,
  value: any,
}

export function DataElement(props: DataElementProps) {
  return (
    <div className={styles.dataElement}>
      <h6>{props.title}</h6>
      <div>{props.value || '\u00A0'}</div>
    </div>
  )
}

export function DataElementList(props: DataElementProps) {
  return (
    <div className={styles.dataElement}>
      <h6>{props.title}</h6>
      <div>{props.value.split(',').map((e : string, index: number) => (<div key={index}>{e}</div>)) || '\u00A0'}</div>
    </div>
  )
}
