import React from "react";
import styles from './ChartStatControl.module.css';
import {Input, Label} from "reactstrap";
import {selectStats, setDisplay, setStat} from "./deviceChartSlice";
import {useSelector} from "react-redux";

interface ChartStatControlProps {
  data: string,
  refresh: any
}

export function ChartStatControl(props: ChartStatControlProps) {
  const stats = useSelector(selectStats)
  return (
    <div className={styles.dataBlock}>
      {/*<Input*/}
      {/*  className="mx-lg-2"*/}
      {/*  id={props.data}*/}
      {/*  type="checkbox"*/}
      {/*  checked={stats[props.data].display}*/}
      {/*  onChange={e => props.refresh(setDisplay({name: props.data, display: e.target.checked}))}*/}
      {/*/>*/}
      <Label for={props.data} className="h6 w-25 mx-2">{props.data}</Label>
      <Input
        value={stats[props.data].stat}
        className="d-inline-block mx-2 w-50"
        type="select"
        disabled={!stats[props.data].display}
        onChange={e => props.refresh(setStat({name: props.data, stat: e.target.value}))}
      >
        <option value='avg'>Average</option>
        <option value='min'>Min</option>
        <option value='max'>Max</option>
        <option value='med'>Median</option>
      </Input>
    </div>
  )
}
