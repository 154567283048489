import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import { Alert } from 'reactstrap';
import {selectProgress, setProgress} from "./progressSlice";


export function Progress() {
  const dispatch = useDispatch()
  const progress = useSelector(selectProgress);
  useEffect(() => {
    if (progress.status === 'success') {
      setTimeout(() => {
        dispatch(setProgress(null as any))
      }, 2000);
    }
  })
  return (
    <Alert color={progress.status}
           isOpen={progress.status !== ''}
           className='float-end'
           toggle={() => {dispatch(setProgress(null as any))}}
    >
      {progress.message}
    </Alert>
  )
}
