import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import {Patient, Patients} from "../../../domain/Patient";
import {getHeaders, handleError, handleResponse} from "../../../utils/fetchUtils";
import {Device, Devices} from "../../../domain/Device";
import {User} from "../../../domain/User";

interface PatientsPayload {
  account_id: string,
  patients: Device[],
}


interface PatientsState {
  list: Devices,
  staffList: User[],
  doctorList: User[],
}

const initialState: PatientsState = {
  list: {},
  staffList: [],
  doctorList: [],
};


export const patientsSlice = createSlice({
  name: 'patients',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<PatientsPayload>) => {
      state.list[action.payload.account_id] = action.payload.patients;
    },
    deletePatientsState: (state) => {
      state.list = {}
    },
    setUserLists: (state, action: PayloadAction<User[]>) => {
      state.staffList = action.payload.filter(user => user.roles.includes('staff'));
      state.doctorList = action.payload.filter(user => user.roles.includes('doctor'));
    },
  },
});

export const { setList, deletePatientsState, setUserLists } = patientsSlice.actions;

export const selectList = (state: RootState) => state.patients.list;
export const selectStaffList = (state: RootState) => state.patients.staffList
export const selectDoctorList = (state: RootState) => state.patients.doctorList

export const setListAsync = (accessToken: string, account_id: string): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/devices/${account_id}?patient_name=exists`, {
    method: 'GET',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then((patients) => {
      dispatch(setList({account_id, patients}));
    })
    .catch((error) => handleError(dispatch, error))
}

export const setUserListsAsync = (accessToken: string, account_id: string): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/users/${account_id}`, {
    method: 'GET',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then((users) => {
      dispatch(setUserLists(users));
    })
    .catch((error) => handleError(dispatch, error))
}

export default patientsSlice.reducer;
