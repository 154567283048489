import {getHeaders, handleError, handleResponse} from "../../utils/fetchUtils";
import {AppThunk} from "../../app/store";

export const updateDeviceAsync = (
  accessToken: string,
  account_id: string,
  device_id: string,
  data: {[key: string]: string | boolean | number},
  mode: string = '',
): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/devices/${account_id}/${device_id}?mode=${mode}`, {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(data)
  }).then(response => handleResponse(dispatch, response))
    .catch((error) => handleError(dispatch, error))
}

