import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {Device} from "../../domain/Device";

interface ConfirmDeviceOffline {
  popup: boolean,
  locationInput: string,
  deviceId: string,
}

const initialState: ConfirmDeviceOffline = {
  popup: false,
  locationInput: '',
  deviceId: ''
}

export const confirmDeviceOffline = createSlice({
  name: 'confirmDeviceOffline',
  initialState,
  reducers: {
    confirmDeviceOfflinePopup: (state, action: PayloadAction<Device>) => {
      state.popup = true
      state.locationInput = action.payload.location
      state.deviceId = action.payload.device_id
    },
    closePopup: (state) => {
      state.popup = false
    },
    setLocationInput: (state, action: PayloadAction<string>) => {
      state.locationInput = action.payload
    },
  }
})

export const {
  confirmDeviceOfflinePopup,
  closePopup,
  setLocationInput,
} = confirmDeviceOffline.actions

export const selectPopup = (state: RootState) => state.confirmDeviceOffline.popup
export const selectLocationInput = (state: RootState) => state.confirmDeviceOffline.locationInput
export const selectDeviceId = (state: RootState) => state.confirmDeviceOffline.deviceId

export default confirmDeviceOffline.reducer;
