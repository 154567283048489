import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import {
  setLoggedIn
} from '../authorization/authorizationSlice';
import {setProgress} from "../progress/progressSlice";
import {getHeaders, handleError, handleResponse} from "../../utils/fetchUtils";

interface ChangePasswordState {
  codeStatus: string,
  message: string,
  code: string,
  password: string,
}

const initialState: ChangePasswordState = {
  codeStatus: '',
  message: '',
  code: '',
  password: '',
};

export const changePasswordSlice = createSlice({
  name: 'changePasword',
  initialState,
  reducers: {
    setCodeStatus: (state, action: PayloadAction<{codeStatus: string, message: string}>) => {
      if (action.payload) {
        state.codeStatus = action.payload.codeStatus
        state.message = action.payload.message
      } else {
        state.codeStatus = ''
        state.message = ''
      }
    },
    setCode: (state, action: PayloadAction<string>) => {
      state.code = action.payload;
    },
    setPassword: (state, action: PayloadAction<string>) => {
      state.password = action.payload;
    },
  },
});

export const { setCodeStatus, setCode, setPassword } = changePasswordSlice.actions

export const selectCodeStatus = (state: RootState) => state.changePassword.codeStatus

export const selectMessage = (state: RootState) => state.changePassword.message

export const selectCode = (state: RootState) => state.changePassword.code

export const selectPassword = (state: RootState) => state.changePassword.password

export const selectChangePasswordState = (state: RootState) => state.changePassword

export const changePasswordAsync = (accessToken: string): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  dispatch(setCodeStatus({codeStatus: 'sending', message: ''}))
  fetch(`${apiUrl}/me/change_password`, {
    method: 'POST',
    headers: headers,
  }).then(response => {
    if (response.ok) {
      response.json().then(data => {
        dispatch(setCodeStatus({codeStatus: 'sent', message: `Email sent to ${data.CodeDeliveryDetails.Destination}`}))
      })
    } else if (response.status === 400) {
      response.json().then(data => {
        dispatch(setCodeStatus({codeStatus: 'error', message: data.response.Error.Message}))
      })
    } else {
      return handleResponse(dispatch, response)
    }
  })
    .catch((error) => handleError(dispatch, error))
}


export const confirmPasswordAsync = (accessToken: string, obj: ChangePasswordState): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  dispatch(setProgress({status: 'secondary', message: 'Changing password...'}))
  fetch(`${apiUrl}/me/confirm_password`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(obj)
  }).then(response => {
    if (response.ok) {
      dispatch(setProgress({status: 'success', message: 'Password changed'}))
    } else {
      response.json().then(data => {
        let message = ''
        if ('kwargs' in data) {
          message = data.kwargs.report
        } else if ('response' in data) {
          message = data.response.Error.Message
        }
        dispatch(setProgress({status: 'danger', message: message}))
      })
    }
    })
    .catch((error) => handleError(dispatch, error))
}

export default changePasswordSlice.reducer;
