import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';

interface ProgressState {
  status: string,
  message: string,
}

const initialState: ProgressState = {
  status: '',
  message: ''
};

export const progressSlice = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    setProgress: (state, action: PayloadAction<{status: string, message: string}>) => {
      if (action.payload) {
        state.status = action.payload.status
        state.message = action.payload.message
      } else {
        state.status = ''
        state.message = ''
      }
    },
  },
});

export const { setProgress } = progressSlice.actions

export const selectProgress = (state: RootState) => state.progress

export default progressSlice.reducer;
