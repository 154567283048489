// @ts-ignore
import { styler } from "react-timeseries-charts"


export const lineStyle = {
  temperature: {
    normal: {stroke: "#589A7D", fill: "none", strokeWidth: 2},
    highlighted: {stroke: "#5a98cb", fill: "none", strokeWidth: 1},
    selected: {stroke: "steelblue", fill: "none", strokeWidth: 1},
    muted: {stroke: "steelblue", fill: "none", opacity: 0.4, strokeWidth: 1}
  },
  flow_setting: {
    normal: {stroke: "#eeb304", fill: "none", strokeWidth: 2},
    highlighted: {stroke: "#5a98cb", fill: "none", strokeWidth: 1},
    selected: {stroke: "steelblue", fill: "none", strokeWidth: 1},
    muted: {stroke: "steelblue", fill: "none", opacity: 0.4, strokeWidth: 1}
  },
  fiO2_setting: {
    normal: {stroke: "#000b73", fill: "none", strokeWidth: 2},
    highlighted: {stroke: "#5a98cb", fill: "none", strokeWidth: 1},
    selected: {stroke: "steelblue", fill: "none", strokeWidth: 1},
    muted: {stroke: "steelblue", fill: "none", opacity: 0.4, strokeWidth: 1}
  },
  spO2: {
    normal: {stroke: "#9b430e", fill: "none", strokeWidth: 2},
    highlighted: {stroke: "#5a98cb", fill: "none", strokeWidth: 1},
    selected: {stroke: "steelblue", fill: "none", strokeWidth: 1},
    muted: {stroke: "steelblue", fill: "none", opacity: 0.4, strokeWidth: 1}
  },
  pulse_rate: {
    normal: {stroke: "rgb(166, 80, 184)", fill: "none", strokeWidth: 2},
    highlighted: {stroke: "#5a98cb", fill: "none", strokeWidth: 1},
    selected: {stroke: "steelblue", fill: "none", strokeWidth: 1},
    muted: {stroke: "steelblue", fill: "none", opacity: 0.4, strokeWidth: 1}
  },
  rox: {
    normal: {stroke: "#d34901", fill: "none", strokeWidth: 2},
    highlighted: {stroke: "#d34901", fill: "none", strokeWidth: 1},
    selected: {stroke: "steelblue", fill: "none", strokeWidth: 1},
    muted: {stroke: "steelblue", fill: "none", opacity: 0.4, strokeWidth: 1}
  },
  respiratory_rate: {
    normal: {stroke: "#5a98cb", fill: "none", strokeWidth: 2},
    highlighted: {stroke: "#5a98cb", fill: "none", strokeWidth: 1},
    selected: {stroke: "steelblue", fill: "none", strokeWidth: 1},
    muted: {stroke: "steelblue", fill: "none", opacity: 0.4, strokeWidth: 1}
  },
  system_status: {
    normal: {stroke: "rgb(166, 80, 184)", fill: "gray", strokeWidth: 2},
    highlighted: {stroke: "#5a98cb", fill: "gray", strokeWidth: 1},
    selected: {stroke: "steelblue", fill: "gray", strokeWidth: 1},
    muted: {stroke: "steelblue", fill: "gray", opacity: 0.4, strokeWidth: 1}
  },
};

export const temperatureAxisStyle = {
  label: { stroke: "none", fill: "#589A7D" },
  ticks: { stroke: "#AAA", opacity: 1, "stroke-dasharray": "1,1" }
}

export const pulseRateAxisStyle = {
  label: { stroke: "none", fill: "rgb(166, 80, 184)" },
  ticks: { stroke: "#AAA", opacity: 1, "stroke-dasharray": "1,1" }
}

export const spO2AxisStyle = {
  label: { stroke: "none", fill: "#9b430e" },
  ticks: { stroke: "#AAA", opacity: 1, "stroke-dasharray": "1,1" }
}

export const respiratoryRateAxisStyle = {
  label: { stroke: "none", fill: "#5a98cb" },
  ticks: { stroke: "#AAA", opacity: 1, "stroke-dasharray": "1,1" }
}

export const roxAxisStyle = {
  label: { stroke: "none", fill: "#5a98cb" },
  ticks: { stroke: "#d34901", opacity: 1, "stroke-dasharray": "5,5" }
}

export const trackerStyle = {
  line: { stroke: "gray", cursor: "crosshair", pointerEvents: "yes" },
  box: { fill: "red", opacity: 1, stroke: "yellow", pointerEvents: "none" },
}

export const areaStyle = styler([
  { key: "therapy", color: "rgba(187,213,243,0.6)" },
  { key: "standby", color: "rgba(237,248,220,0.77)" },
  { key: "spO2_area", color: "rgba(155,67,14,0.25)" },
  { key: "pulse_rate_area", color: "rgba(166,80,184,0.25)" },
]);
