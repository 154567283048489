import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import {Patient, Patients} from "../../../domain/Patient";
import {getHeaders, handleError, handleResponse} from "../../../utils/fetchUtils";
import {User} from "../../../domain/User";

interface PastPatientsPayload {
  account_id: string,
  patients: Patient[]
}


interface PastPatientsState {
  list: Patients,
  staffList: User[],
  doctorList: User[],
}

const initialState: PastPatientsState = {
  list: {},
  staffList: [],
  doctorList: [],
};


export const pastPatientsSlice = createSlice({
  name: 'pastPatients',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<PastPatientsPayload>) => {
      state.list[action.payload.account_id] = action.payload.patients;
    },
    deletePatientsState: (state) => {
      state.list = {}
    },
    setUserLists: (state, action: PayloadAction<User[]>) => {
      state.staffList = action.payload.filter(user => user.roles.includes('staff'));
      state.doctorList = action.payload.filter(user => user.roles.includes('doctor'));
    },
  },
});

export const { setList, deletePatientsState, setUserLists } = pastPatientsSlice.actions;

export const selectList = (state: RootState) => state.pastPatients.list;
export const selectStaffList = (state: RootState) => state.pastPatients.staffList
export const selectDoctorList = (state: RootState) => state.pastPatients.doctorList

export const setListAsync = (accessToken: string, account_id: string): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/patients/${account_id}?patient_name=exists`, {
    method: 'GET',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then((patients) => {
      dispatch(setList({account_id, patients}));
    })
    .catch((error) => handleError(dispatch, error))
}

export const setUserListsAsync = (accessToken: string, account_id: string): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/users/${account_id}`, {
    method: 'GET',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then((users) => {
      dispatch(setUserLists(users));
    })
    .catch((error) => handleError(dispatch, error))
}

export default pastPatientsSlice.reducer;
