import React, {useEffect} from 'react';
import { Authorization } from './features/authorization/Authorization';
import { Me } from './features/me/Me';
import './App.css';
import { setOriginalFetch } from 'react-fetch-progressbar';
import { ProgressBar } from 'react-fetch-progressbar';
import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import {
  Navbar, Nav, NavItem, Container,
  UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, NavbarBrand
} from 'reactstrap';
import {useDispatch, useSelector} from "react-redux";
import {selectIsLoggedIn, setLoggedIn} from "./features/authorization/authorizationSlice";
import {Accounts} from "./features/accounts/list/Accounts";
import {EditAccount} from "./features/accounts/edit/EditAccount";
import {EditUser} from "./features/users/edit/EditUser";
import {selectAccountId, selectName, selectRoles} from "./features/me/meSlice";
import {Users} from "./features/users/list/Users";
import {Devices} from "./features/devices/list/Devices";
import {ChangePassword} from "./features/changePassword/ChangePassword";
import {DeviceState} from "./features/devices/state/DeviceState";
import {Patients} from "./features/patients/list/Patients";
import {PatientState} from "./features/patients/state/PatientState";
import {PastDevices} from "./features/devices/past/PastDevices";
import {PastPatients} from "./features/patients/past/PastPatients";
import {PastPatientState} from "./features/patients/state/PastPatientState";
import {PastDeviceState} from "./features/devices/state/PastDeviceState";
import {DeviceChart} from "./features/devices/chart/DeviceChart";
import {RoxChart} from "./features/devices/chart/RoxChart";
import {Home} from "./features/home/Home";

export const siteTitlePrefix = 'Invent InVue: '

function App() {
  setOriginalFetch(window.fetch)
  const roles = useSelector(selectRoles)
  const account_id = useSelector(selectAccountId)
  // (window as any).fetch = progressBarFetch
  const isLoggedIn = useSelector(selectIsLoggedIn)
  const loggedInName = useSelector(selectName)
  const dispatch = useDispatch()
  useEffect(() => {
    if (isLoggedIn) {
      document.body.classList.remove('body-logged-off')
    } else {
      document.body.classList.add('body-logged-off')
    }
  })
  return (
    <BrowserRouter>
      <Container>
        <ProgressBar />
        {isLoggedIn ? <>
          <Navbar className='border-bottom mb-2'>
            <Nav>
              <NavbarBrand href="/">
                <img src='/logo.png' height='40px'/>
              </NavbarBrand>
              {roles && roles.includes('root') &&
                <NavItem>
                  <Link to="/accounts" className="nav-link">Accounts</Link>
                </NavItem>
              }
              {roles && !roles.includes('root') &&
                <>
                  <NavItem>
                    <Link to="/" className="nav-link">Home</Link>
                  </NavItem>
                  <NavItem>
                    <Link to={`/current/devices/${account_id}`} className="nav-link">
                      Devices
                    </Link>
                  </NavItem>
                  <NavItem>
                    <Link to={`/current/patients/${account_id}`} className="nav-link">
                      Patients
                    </Link>
                  </NavItem>
                  {roles && roles.includes('admin') &&
                    <UncontrolledDropdown nav inNavbar>
                      <DropdownToggle nav caret>
                        Account
                      </DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem tag={Link} to={`/users/${account_id}`}>
                          Manage Users
                        </DropdownItem>
                      </DropdownMenu>
                    </UncontrolledDropdown>
                  }
                </>
              }
            </Nav>
            <Nav className="float-end">
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  {loggedInName}
                </DropdownToggle>
                <DropdownMenu>
                  <DropdownItem tag={Link} to="/me">
                    My Profile
                  </DropdownItem>
                  <DropdownItem tag={Link} to="/me/change-password">
                    Change Password
                  </DropdownItem>
                  <DropdownItem tag={Link} to="#" onClick={() => dispatch(setLoggedIn(false))}>
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
            </Nav>
          </Navbar>
          <Routes>
            <Route path='/' element={<Home accountId={account_id}/>}/>
            <Route path='accounts' element={<Accounts/>}/>
            <Route path='accounts/:id' element={<EditAccount/>}/>
            <Route path='users/:account_id' element={<Users/>}/>
            <Route path='users/:account_id/:user_id' element={<EditUser/>}/>
            <Route path='devices/:account_id/:device_id/:patient_count' element={<DeviceState/>}/>
            <Route path='devices/:account_id/:device_id/:patient_count/chart' element={<DeviceChart chartType='multiple' embedded={false}/>}/>
            <Route path='devices/:account_id/:device_id/:patient_count/rox' element={<RoxChart/>}/>
            <Route path='past/devices/:account_id/:patient_id' element={<PastDeviceState/>}/>
            <Route path='past/devices/:account_id/:patient_id/chart' element={<DeviceChart chartType='multiple' embedded={false}/>}/>
            <Route path='current/devices/:account_id' element={<Devices/>}/>
            <Route path='past/devices/:account_id' element={<PastDevices/>}/>
            <Route path='current/patients/:account_id' element={<Patients/>}/>
            <Route path='past/patients/:account_id' element={<PastPatients/>}/>
            <Route path='patients/:account_id/:device_id/:patient_count' element={<PatientState/>}/>
            <Route path='patients/:account_id/:device_id/:patient_count/chart' element={<DeviceChart chartType='multiple' embedded={false}/>}/>
            <Route path='patients/:account_id/:device_id/:patient_count/rox' element={<RoxChart/>}/>
            <Route path='past/patients/:account_id/:patient_id' element={<PastPatientState/>}/>
            <Route path='past/patients/:account_id/:patient_id/chart' element={<DeviceChart chartType='multiple' embedded={false}/>}/>
            <Route path='me' element={<Me/>}/>
            <Route path='me/change-password' element={<ChangePassword/>}/>
          </Routes>
        </> : <>
          <Authorization />
        </>}
      </Container>
    </BrowserRouter>
  );
}

export default App;
