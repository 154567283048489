import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {Spinner} from "reactstrap";
import {Alarms} from "../alarms/Alarms";
import {selectRoles} from "../me/meSlice";

interface HomeProps {
  accountId: string,
}
export function Home(props: HomeProps) {
  const account_id = props.accountId
  const roles = useSelector(selectRoles)
  return roles && roles.includes('root')
    ? (
      <>
      </>
    )
    : account_id ? (
      <>
        <Alarms accountId={account_id}/>
      </>
    ) : (
      <Spinner animation="border" className="m-5"/>
    )
}
