import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectDoctorList, selectList, selectStaffList, setListAsync, setUserListsAsync} from './patientsSlice';
import {selectAccessToken} from "../../authorization/authorizationSlice";
import {Breadcrumb, BreadcrumbItem, Spinner, Table} from "reactstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import {SystemStatus} from "../../../domain/Device";
import styles from "./Patients.module.css";
import {AutoRefresh} from "../../../components/AutoRefresh";


export function Patients() {
  const { account_id } = useParams() as any
  const patients = useSelector(selectList);
  const list = patients[account_id]
  const dispatch = useDispatch()
  const accessToken = useSelector(selectAccessToken)
  const navigate = useNavigate()
  const staffList = useSelector(selectStaffList)
  const doctorList = useSelector(selectDoctorList)
  const title = 'Patients'

  useEffect(() => {
      dispatch(setUserListsAsync(accessToken, account_id))
      dispatch(setListAsync(accessToken, account_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return list == null ? (<Spinner animation="border" className="m-5"/>) : (
    <>
      <h1>{title}</h1>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/">Home</BreadcrumbItem>
        <BreadcrumbItem active tag="span">{title}</BreadcrumbItem>
      </Breadcrumb>

      <AutoRefresh callback={() => dispatch(setListAsync(accessToken, account_id))} timeout={2000} className="float-end mt-3"/>
      <div className="tab-toggler">
        <button type="button" className="btn btn-dark m-1"
                onClick={() => navigate(`/current/patients/${account_id}`)}>Current</button>
        <button type="button" className="btn btn-light m-1"
                onClick={() => navigate(`/past/patients/${account_id}`)}>Prior</button>
      </div>
      <Table striped bordered hover>
      <thead>
        <tr>
          <th className={styles.alertHead}>
            <svg className="bi" width="20" height="20" fill="currentColor">
              <use xlinkHref="/bootstrap-icons.svg#bell"/>
            </svg>
          </th>
          <th>Patient name</th>
          <th>First therapy date</th>
          <th>Status</th>
          <th>Location</th>
          <th>RT contact</th>
          <th>Referral doctor</th>
          <th>Clinical notes</th>
        </tr>
      </thead>
      <tbody>
      {list.map(device => (
        <tr key={device.device_id}>
          <td>
            {device.alarm !== '0' &&
              <svg className="bi" width="20" height="20" fill="currentColor">
                <use xlinkHref="/bootstrap-icons.svg#bell-fill"/>
              </svg>
            }
          </td>
          <td>
            <Link to={`/patients/${account_id}/${device.device_id}/-`}>{device.patient_name}</Link>
          </td>
          <td>{device.first_therapy_date}</td>
          <td>{SystemStatus[device.system_status]}</td>
          <td>{device.location}</td>
          <td>
            {staffList.filter(item => item.user_id === device.staff_user_id).map(item => item.name)}
          </td>
          <td>
            {doctorList.filter(item => item.user_id === device.doctor_user_id).map(item => item.name)}
          </td>
          <td>{device.clinical_notes}</td>
        </tr>
      ))}
      </tbody>
    </Table>
    </>
  );
}
