import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import {Device, Devices} from "../../../domain/Device";
import {getHeaders, handleError, handleResponse} from "../../../utils/fetchUtils";

interface PastDevicesPayload {
  account_id: string,
  devices: Device[]
}


interface PastDevicesState {
  list: Devices
}

const initialState: PastDevicesState = {
  list: {}
};


export const pastDevicesSlice = createSlice({
  name: 'pastDevices',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<PastDevicesPayload>) => {
      state.list[action.payload.account_id] = action.payload.devices;
    },
    deletePastDevicesState: (state) => {
      state.list = {}
    },
  },
});

export const { setList, deletePastDevicesState } = pastDevicesSlice.actions;

export const selectList = (state: RootState) => state.pastDevices.list;

export const setListAsync = (accessToken: string, account_id: string): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/patients/${account_id}?patient_name=not_exists`, {
    method: 'GET',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then((devices) => {
      dispatch(setList({account_id, devices}));
    })
    .catch((error) => handleError(dispatch, error))
}


export default pastDevicesSlice.reducer;
