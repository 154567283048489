import React, {useEffect} from 'react';
import { useSelector } from 'react-redux';
import {selectAccountId, selectEmail, selectName, selectRoles} from './meSlice';
import {Form, FormGroup, Input, Label, Spinner} from "reactstrap";
import {siteTitlePrefix} from "../../App";
import {selectAccount} from "../accounts/edit/editAccountSlice";

export function Me() {
  const email = useSelector(selectEmail);
  const name = useSelector(selectName);
  const roles = useSelector(selectRoles);
  const account_id = useSelector(selectAccountId);
  const account = useSelector(selectAccount)
  const title = 'Profile'

  useEffect(() => {
    document.title = siteTitlePrefix + title
  }, []);

  return email == null ? (<Spinner animation="border" className="m-5"/>) : (
    <>
      <h1>{title}</h1>
      <Form>
        <FormGroup>
          <Label for="email">Name</Label>
          <Input
            name="name"
            id="name"
            placeholder="Name"
            value={name}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            name="email"
            id="email"
            placeholder="Email"
            value={email}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="roles">Roles</Label>
          <Input
            name="roles"
            id="roles"
            placeholder="Roles"
            value={roles}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label for="account">Account ID</Label>
          <Input
            name="account_id"
            id="account_id"
            placeholder="Account ID"
            value={account_id}
            disabled
          />
        </FormGroup>
      </Form>
    </>
  );
}
