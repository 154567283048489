import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  createAsync, deleteUserState,
  selectUser,
  setUserAsync,
  setName,
  updateAsync, setRole, setEmail, selectMessage, selectStatus, setStatus, resetStatus
} from './editUserSlice';
import {selectAccessToken} from "../../authorization/authorizationSlice";
import {Button, Form, FormGroup, Input, Label, Spinner} from "reactstrap";
import {Alert} from 'reactstrap';
import {useParams, useNavigate} from 'react-router-dom'
import {User} from "../../../domain/User";
import {deleteUsersState} from "../list/usersSlice";
import {siteTitlePrefix} from "../../../App";
import {selectEmail} from "../../me/meSlice";


export function EditUser() {
  const { account_id, user_id } = useParams()
  const obj : User = useSelector(selectUser);
  const this_email = useSelector(selectEmail)
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const accessToken = useSelector(selectAccessToken)
  const title = (user_id !== 'new' ? 'Edit': 'New') + 'User'
  const message = useSelector(selectMessage)
  const status = useSelector(selectStatus)
  if (status === 'ok') {
    setTimeout(()=> {
      dispatch(resetStatus())
      navigate(-1)
    }, 500)
  }
  useEffect(() => {
    document.title = siteTitlePrefix + title
    if (user_id !== 'new') {
      dispatch(deleteUserState())
      dispatch(setUserAsync(accessToken, account_id as string, user_id as string));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return obj == null ? (<Spinner animation="border" className="m-5"/>) : (
    <>
      <Alert className='float-end w-50' color="success" isOpen={status === 'ok'}>
        {message}
      </Alert>
      <Alert className='float-end w-50' color="danger" isOpen={status === 'error'}
             toggle={() => {dispatch(setStatus({status: '', message: ''}))}}>
        {message}
      </Alert>
      <Alert className='float-end w-50' color="secondary" isOpen={status === 'saving'} >
        {message}
      </Alert>
      <h1>
        {title}
      </h1>
      <Form>
        <FormGroup>
          <Label for="account_id">Account ID</Label>
          <Input disabled
            name="account_id"
            id="account_id"
            placeholder="Account ID"
            value={account_id}
          />
        </FormGroup>
        <FormGroup>
          <Label for="user_id">Email</Label>
          <Input
            name="email"
            id="email"
            placeholder="Email"
            value={obj.email}
            onChange={e => dispatch(setEmail(e.target.value))}
          />
        </FormGroup>
        <FormGroup>
          <Label for="name">Name</Label>
          <Input
            name="name"
            id="name"
            placeholder="Name"
            value={obj.name}
            onChange={e => dispatch(setName(e.target.value))}
          />
        </FormGroup>
        <FormGroup>
          <Label className="d-block">Roles</Label>
          <Label for="role_admin" className="m-1">Admin</Label>
          <Input
            disabled={user_id !== 'new' && this_email === obj.email}
            className="m-2"
            type="checkbox"
            name="admin"
            id="role_admin"
            value='admin'
            checked={obj.roles.includes('admin')}
            onChange={e => dispatch(setRole({name: e.target.value, enabled: e.target.checked}))}
          />
          <span className="m-2">|</span>
          <Label for="role_provision" className="m-1">Provision</Label>
          <Input
            className="m-2"
            type="checkbox"
            name="provision"
            id="role_provision"
            value='provision'
            checked={obj.roles.includes('provision')}
            onChange={e => dispatch(setRole({name: e.target.value, enabled: e.target.checked}))}
          />
          <span className="m-2">|</span>
          <Label for="role_staff" className="m-1">Staff</Label>
          <Input
            className="m-2"
            type="checkbox"
            name="staff"
            id="role_staff"
            value='staff'
            checked={obj.roles.includes('staff')}
            onChange={e => dispatch(setRole({name: e.target.value, enabled: e.target.checked}))}
          />
          <span className="m-2">|</span>
          <Label for="role_staff" className="m-1">Doctor</Label>
          <Input
            className="m-2"
            type="checkbox"
            name="doctor"
            id="role_doctor"
            value='doctor'
            checked={obj.roles.includes('doctor')}
            onChange={e => dispatch(setRole({name: e.target.value, enabled: e.target.checked}))}
          />
        </FormGroup>
        <Button className="m-2" onClick={() => {
            dispatch(setStatus({status: 'saving', message: 'Applying changes...'}))
            user_id === 'new'
              ? dispatch(createAsync(accessToken, account_id as string, obj))
              : dispatch(updateAsync(accessToken, account_id as string, obj))
            }
        }>
          Save
        </Button>
        <Button className="m-2"
          onClick={() => {
            dispatch(resetStatus())
            dispatch(deleteUsersState())
            navigate(-1)
          }}>
          Cancel
        </Button>
      </Form>
    </>
  );
}
