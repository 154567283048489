import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import counterReducer from '../features/counter/counterSlice';
import authorizationReducer from '../features/authorization/authorizationSlice';
import meReducer from '../features/me/meSlice';
import changePasswordReducer from '../features/changePassword/changePasswordSlice';
import accountsReducer from '../features/accounts/list/accountsSlice';
import editAccountReducer from '../features/accounts/edit/editAccountSlice';
import usersReducer from '../features/users/list/usersSlice';
import editUserReducer from '../features/users/edit/editUserSlice';
import devicesReducer from '../features/devices/list/devicesSlice';
import alarmsReducer from '../features/alarms/alarmsSlice';
import pastDevicesReducer from '../features/devices/past/pastDevicesSlice';
import patientsReducer from '../features/patients/list/patientsSlice';
import pastPatientsReducer from '../features/patients/past/pastPatientsSlice';
import deviceStateReducer from '../features/devices/state/deviceStateSlice';
import deviceChartReducer from '../features/devices/chart/deviceChartSlice';
import roxChartReducer from '../features/devices/chart/roxChartSlice';
import patientStateReducer from '../features/patients/state/patientStateSlice';
import progressReducer from '../features/progress/progressSlice';
import editDeviceDetailsReducer from '../features/modals/editDeviceDetailsSlice';
import confirmDeviceOfflineReducer from '../features/modals/confirmDeviceOfflineSlice';
import correctTimeDriftReducer from '../features/modals/correctTimeDriftSlice';
import assignToPatientReducer from '../features/modals/assignToPatientSlice';
import removePatientReducer from '../features/modals/removePatientSlice';
import changeCareContactReducer from '../features/modals/changeCareContactSlice';
import autoRefreshReducer from '../components/autoRefreshSlice';


export const store = configureStore({
  reducer: {
    progress: progressReducer,
    counter: counterReducer,
    authorization: authorizationReducer,
    me: meReducer,
    changePassword: changePasswordReducer,
    accounts: accountsReducer,
    account: editAccountReducer,
    users: usersReducer,
    user: editUserReducer,
    devices: devicesReducer,
    alarms: alarmsReducer,
    pastDevices: pastDevicesReducer,
    patients: patientsReducer,
    pastPatients: pastPatientsReducer,
    deviceState: deviceStateReducer,
    deviceChart: deviceChartReducer,
    roxChart: roxChartReducer,
    patientState: patientStateReducer,
    editDeviceDetails: editDeviceDetailsReducer,
    assignToPatient: assignToPatientReducer,
    removePatient: removePatientReducer,
    changeCareContact: changeCareContactReducer,
    autoRefresh: autoRefreshReducer,
    confirmDeviceOffline: confirmDeviceOfflineReducer,
    correctTimeDrift: correctTimeDriftReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
