
export function formatInt(value: number) {
  if (value >= 0 && value < 9999) {
    if (value % 1 === 0) {
      return value
    } else {
      return value.toFixed(1)
    }
  } else {
    return NaN
  }
}

export function currentDateMinus(hours : number) {
  const date = new Date()
  date.setHours(new Date().getHours() - hours)
  return date
}

export function isValid(e : number) {
  return !isNaN(e) && -9999 < e && e < 9999
}

export function validOrDefault(e : number, e1 : number, def: number) {
  return isValid(e) && e !== e1 ? e : def
}

export function allSearchParams(searchParams : URLSearchParams, remove: string[] = []) {
  const params: { key: string; value: string }[] = [];
  searchParams.forEach((value, key) => remove.includes(key) || params.push({key: key, value: value}))

  // @ts-ignore
  // eslint-disable-next-line no-sequences
  return params.reduce((map, obj) => (map[obj.key] = obj.value, map), {})
}
