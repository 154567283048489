import {useDispatch, useSelector} from "react-redux";
import {
  clearRefreshInterval,
  selectAutoRefresh,
  setAutoRefresh,
} from "./autoRefreshSlice";
import React, {useEffect} from "react";
import {Input, Label} from "reactstrap";

interface AutoRefreshProps {
  callback: any,
  timeout: number,
  className: string,
}

export function AutoRefresh(props: AutoRefreshProps) {
  const autoRefresh = useSelector(selectAutoRefresh)
  const dispatch = useDispatch()

  useEffect(() => {
    if (autoRefresh) {
      dispatch(setAutoRefresh({
        autoRefresh: true,
        refreshInterval: setInterval(() => props.callback(), props.timeout)
      }))
    }
    return () => {
      dispatch(clearRefreshInterval())
    }
  }, []);

  return (
      <div className={props.className}>
        <svg role="button" className="bi mx-2" width="20" height="20" fill="currentColor"
             onClick={() => props.callback()}>
          <use xlinkHref="/bootstrap-icons.svg#repeat"/>
        </svg>
        <Label for="autoRefresh" className="fw-normal">
          Auto refresh
        </Label>
        <Input
          id="autoRefresh"
          className="mx-lg-1"
          type="checkbox"
          checked={autoRefresh}
          onChange={e => {
            dispatch(setAutoRefresh({
              autoRefresh: e.target.checked,
              refreshInterval: e.target.checked
                ? setInterval(() => props.callback(), 2000)
                : null
            }))
          }}
        />
      </div>
  )
}
