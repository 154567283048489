const authEndpoint = (window as any).env('LOGIN_URL');

const scopes = [
  'email', 'openid', 'profile', 'aws.cognito.signin.user.admin'
];

export const getAuthorizeHref = (): string => {
  const clientId = (window as any).env('CLIENT_ID');
  const redirectUri = (window as any).env('REDIRECT_URI');
  return `${authEndpoint}?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join("%20")}&response_type=token`;
}
