import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectList, setListAsync} from './usersSlice';
import {selectAccessToken, selectIsLoggedIn} from "../../authorization/authorizationSlice";
import {Button, Spinner, Table} from "reactstrap";
import {deleteAsync, newUser} from "../edit/editUserSlice";
import {Link, useNavigate, useParams} from "react-router-dom";
import { confirm } from "react-confirm-box";
import {siteTitlePrefix} from "../../../App";
import {selectEmail} from "../../me/meSlice";

interface AccountId {
  account_id: string
}

export function AdminUsers(props: AccountId) {
  const account_id = props.account_id
  const users = useSelector(selectList);
  const list = users[account_id]
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector(selectAccessToken)
  const title = 'Users'

  useEffect(() => {
      dispatch(setListAsync(accessToken, account_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return list == null ? (<Spinner animation="border" className="m-5"/>) : (
    <>
      <h1>Users</h1>
      <Button className="m-2"
        onClick={() => {
          dispatch(newUser())
          navigate(`/users/${account_id}/new`)
        }}>
        New User
      </Button>
      <Table striped bordered hover>
      <thead>
        <tr>
          <th>Email</th>
          <th>Account ID</th>
          <th>Name</th>
          <th>Roles</th>
          <th>Modified</th>
        </tr>
      </thead>
      <tbody>
      {list.map(item => (
        <tr key={item.user_id}>
          <td>
            <Link to={'/users/' + item.account_id + '/' + item.user_id}>{item.email}</Link>
          </td>
          <td>{item.account_id}</td>
          <td>{item.name}</td>
          <td>{item.roles}</td>
          <td>{item.modified}</td>
          <td>
            <Button
              onClick={async () => {
                if (await confirm("Are you sure?")) {
                  dispatch(deleteAsync(accessToken, account_id, item.user_id))
                }
              }}>
              Delete
            </Button>
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
    </>
  );
}


export function Users() {
  const { account_id } = useParams() as any
  console.log('Users', account_id)
  const users = useSelector(selectList);
  const email = useSelector(selectEmail)
  const list = users[account_id]
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector(selectAccessToken)
  const title = 'Users'

  useEffect(() => {
    document.title = siteTitlePrefix + title
  }, []);

  useEffect(() => {
    dispatch(setListAsync(accessToken, account_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return list == null ? (<Spinner animation="border" className="m-5"/>) : (
    <>
      <h1>Users</h1>
      <Button className="m-2"
              onClick={() => {
                dispatch(newUser())
                navigate(`/users/${account_id}/new`)
              }}>
        New User
      </Button>
      <Table striped bordered hover>
        <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Roles</th>
          <th>Modified</th>
        </tr>
        </thead>
        <tbody>
        {list.map(item => (
          <tr key={item.user_id}>
            <td>
              <Link to={'/users/' + item.account_id + '/' + item.user_id}>{item.name}</Link>
            </td>
            <td>{item.email}</td>
            <td>{item.roles}</td>
            <td>{item.modified}</td>
            <td>
              <Button
                disabled={item.email === email}
                onClick={async () => {
                  if (await confirm("Are you sure?")) {
                    dispatch(deleteAsync(accessToken, account_id, item.user_id))
                  }
                }}>
                Delete
              </Button>
            </td>
          </tr>
        ))}
        </tbody>
      </Table>
    </>
  );
}
