import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  setLoggedIn,
  setAccessToken,
  setTokenExpiryDate,
  selectIsLoggedIn,
} from './authorizationSlice';
import {setUserProfileAsync} from '../me/meSlice';
import { getAuthorizeHref } from '../../oauthConfig';
import { getHashParams, removeHashParamsFromUrl } from '../../utils/hashUtils';
import {NavItem, NavLink} from "reactstrap";

const hashParams = getHashParams();
const hashParams_id_token = hashParams.id_token
const hashParams_expires_in = hashParams.expires_in
removeHashParamsFromUrl()

export function Authorization() {
  const isLoggedIn = useSelector(selectIsLoggedIn);
  const dispatch = useDispatch();
  const access_token = hashParams_id_token || localStorage.getItem('access_token')
  const expires_in = hashParams_expires_in || localStorage.getItem('expires_in')
  if (hashParams_id_token) {
    localStorage.setItem('access_token', hashParams_id_token)
  }
  if (expires_in) {
    localStorage.setItem('expires_in', expires_in)
  }
  useEffect(() => {
    if (access_token) {
      document.body.classList.remove('body-logged-off')
      dispatch(setLoggedIn(true))
      dispatch(setAccessToken(access_token))
      dispatch(setTokenExpiryDate(Number(expires_in)))
      dispatch(setUserProfileAsync(access_token))
      if (hashParams_id_token) {
        // to remove hashParams_id_token
        window.location.reload()
      }
    } else if (!isLoggedIn) {
      window.open(getAuthorizeHref(), '_self')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/*{!isLoggedIn &&*/}
      {/*  <NavItem>*/}
      {/*    <NavLink href='#'*/}
      {/*             onClick={() => window.open(getAuthorizeHref(), '_self')}*/}
      {/*             className="nav-link">Login</NavLink>*/}
      {/*  </NavItem>*/}
      {/*}*/}
    </>
  );
}
