import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {Device} from "../../domain/Device";
import {User} from "../../domain/User";

interface AssignToPatient {
  popup: boolean,
  patientNameInput: string,
  patientDateOfBirthInput: string,
  patientGenderInput: string,
  patientIdInput: string,
  otherPatientIdInput: string,
  clinicalNotesInput: string,
  doctorUserIdInput: string,
  doctorList: User[],
}

const initialState: AssignToPatient = {
  popup: false,
  patientNameInput: '',
  patientDateOfBirthInput: '',
  patientGenderInput: '',
  patientIdInput: '',
  otherPatientIdInput: '',
  clinicalNotesInput: '',
  doctorUserIdInput: '',
  doctorList: [],
}

export const assignToPatient = createSlice({
  name: 'device',
  initialState,
  reducers: {
    assignToPatientPopup: (state, action: PayloadAction<{device: Device, doctorList: User[]}>) => {
      state.popup = true
      state.patientNameInput = action.payload.device.patient_name
      state.patientDateOfBirthInput = action.payload.device.patient_date_of_birth
      state.patientGenderInput = action.payload.device.patient_gender
      state.patientIdInput = action.payload.device.patient_id
      state.otherPatientIdInput = action.payload.device.other_patient_id
      state.clinicalNotesInput = action.payload.device.clinical_notes
      state.doctorUserIdInput = action.payload.device.doctor_user_id
      state.doctorList = action.payload.doctorList
    },
    closePopup: (state) => {
      state.popup = false
    },
    setPatientNameInput: (state, action: PayloadAction<string>) => {
      state.patientNameInput = action.payload
    },
    setPatientDateOfBirthInput: (state, action: PayloadAction<string>) => {
      state.patientDateOfBirthInput = action.payload
    },
    setPatientGenderInput: (state, action: PayloadAction<string>) => {
      state.patientGenderInput = action.payload
    },
    setPatientIdInput: (state, action: PayloadAction<string>) => {
      state.patientIdInput = action.payload
    },
    setOtherPatientIdInput: (state, action: PayloadAction<string>) => {
      state.otherPatientIdInput = action.payload
    },
    setClinicalNotesInput: (state, action: PayloadAction<string>) => {
      state.clinicalNotesInput = action.payload
    },
    setDoctorUserIdInput: (state, action: PayloadAction<string>) => {
      state.doctorUserIdInput = action.payload
    },
  }
})

export const {
  assignToPatientPopup, closePopup,
  setPatientNameInput, setPatientDateOfBirthInput, setPatientGenderInput,
  setPatientIdInput, setOtherPatientIdInput, setClinicalNotesInput,
  setDoctorUserIdInput,

} = assignToPatient.actions

export const selectPopup = (state: RootState) => state.assignToPatient.popup
export const selectPatientNameInput = (state: RootState) => state.assignToPatient.patientNameInput
export const selectPatientDateOfBirthInput = (state: RootState) => state.assignToPatient.patientDateOfBirthInput
export const selectPatientGenderInput = (state: RootState) => state.assignToPatient.patientGenderInput
export const selectPatientIdInput = (state: RootState) => state.assignToPatient.patientIdInput
export const selectOtherPatientIdInput = (state: RootState) => state.assignToPatient.otherPatientIdInput
export const selectClinicalNotesInput = (state: RootState) => state.assignToPatient.clinicalNotesInput
export const selectDoctorUserIdInput = (state: RootState) => state.assignToPatient.doctorUserIdInput
export const selectDoctorList = (state: RootState) => state.assignToPatient.doctorList

export default assignToPatient.reducer;
