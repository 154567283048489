import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectList, setListAsync} from './accountsSlice';
import {selectAccessToken, selectIsLoggedIn} from "../../authorization/authorizationSlice";
import {Button, Spinner, Table} from "reactstrap";
import {Account} from "../../../domain/Account";
import {deleteAsync, newAccount} from "../edit/editAccountSlice";
import {Link, useNavigate} from "react-router-dom";
import { confirm } from "react-confirm-box";
import {siteTitlePrefix} from "../../../App";


export function Accounts() {
  const list : Array<Account> = useSelector(selectList);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const accessToken = useSelector(selectAccessToken)
  const title = 'Accounts'

  useEffect(() => {
    document.title = siteTitlePrefix + title
  }, []);

  useEffect(() => {
      dispatch(setListAsync(accessToken));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return list == null ? (<Spinner animation="border" className="m-5"/>) : (
    <>
      <h1>{title}</h1>
      <Button className="m-2"
        onClick={() => {
          dispatch(newAccount())
          navigate('/accounts/new')
        }}>
        New Account
      </Button>
      <Table striped bordered hover>
      <thead>
        <tr>
          <th>Id</th>
          <th>Name</th>
          <th>Modified</th>
          <th></th>
        </tr>
      </thead>
      <tbody>
      {list.map(item => (
        <tr key={item.id}>
          <td>
            <Link to={item.id}>{item.id}</Link>
          </td>
          <td>{item.name}</td>
          <td>{item.modified}</td>
          <td>
            <Button
              onClick={async () => {
                if (await confirm("Are you sure?")) {
                  dispatch(deleteAsync(accessToken, item.id))
                }
              }}>
              Delete
            </Button>
          </td>
        </tr>
      ))}
      </tbody>
    </Table>
    </>
  );
}
