import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import {getHeaders, handleError, handleResponse} from "../../../utils/fetchUtils";
import {Device, History, Stat, Therapy} from "../../../domain/Device";
import {currentDateMinus, isValid, validOrDefault} from "../../../utils/formatUtils";

interface Ranges {
  [key: string]: {min: number, max: number}
}

interface Stats {
  temperature: Stat,
  flow_setting: Stat,
  fiO2_setting: Stat,
  spO2: Stat,
  pulse_rate: Stat,
  respiratory_rate: Stat,
  rox: Stat,
}

interface RoxChart {
  therapy: Therapy | null,
  stats: Stats | null,
  bin: number,
  relative: number,
  date: string,
  direction: string,
  refreshInterval: any,
  requestInProgress: boolean,
  ranges: Ranges,
  autoScale: boolean,
}

const initialState: RoxChart = {
  therapy: null,
  stats: null,
  bin: 60*10,
  relative: 3600*12,
  date: currentDateMinus(0).toISOString(),
  direction: 'Before',
  refreshInterval: null,
  requestInProgress: false,
  ranges: {
    'timeY': {min: 0, max: 12},
    'timeX': {min: 0, max: 12},
    'spO2Y': {min: 80, max: 100},
    'spO2X': {min: 0, max: 12},
    'rrY': {min: 0, max: 50},
    'rrX': {min: 0, max: 12},
  },
  autoScale: true,
}

export const roxChart = createSlice({
  name: 'roxChart',
  initialState,
  reducers: {
    setTherapy: (state, action: PayloadAction<{therapy: Therapy, stats: Stats} | null>) => {
      if (action.payload) {
        state.therapy = action.payload.therapy
        state.stats = action.payload.stats
        if (state.autoScale) {
          const stats = state.stats
          state.ranges['timeY'].max = validOrDefault(stats.rox.max, stats.rox.min, 12)
          state.ranges['timeY'].min = validOrDefault(stats.rox.min, stats.rox.max, 0)

          state.ranges['spO2Y'].max = validOrDefault(stats.spO2.max, stats.spO2.min, 100)
          state.ranges['spO2Y'].min = validOrDefault(stats.spO2.min, stats.spO2.max, 80)

          state.ranges['spO2X'].max = validOrDefault(stats.rox.max, stats.rox.min, 12)
          state.ranges['spO2X'].min = validOrDefault(stats.rox.min, stats.rox.max, 0)

          state.ranges['rrY'].max = validOrDefault(stats.respiratory_rate.max, stats.respiratory_rate.min, 50)
          state.ranges['rrY'].min = validOrDefault(stats.respiratory_rate.min, stats.respiratory_rate.max, 0)

          state.ranges['rrX'].max = validOrDefault(stats.rox.max, stats.rox.min, 12)
          state.ranges['rrX'].min = validOrDefault(stats.rox.min, stats.rox.max, 0)
        }
      } else {
        state.therapy = null
        state.stats = null
      }
    },
    setRelative: (state, action: PayloadAction<{relative: number, bin: number}>) => {
      state.relative = action.payload.relative
      state.bin = action.payload.bin
      state.therapy = null
      if (state.refreshInterval) {
        clearInterval(state.refreshInterval)
        state.refreshInterval = null
      }
    },
    setDate: (state, action: PayloadAction<string>) => {
      state.date = action.payload
      state.therapy = null
      if (state.refreshInterval) {
        clearInterval(state.refreshInterval)
        state.refreshInterval = null
      }
    },
    setDirection: (state, action: PayloadAction<string>) => {
      state.direction = action.payload
      state.therapy = null
      if (state.refreshInterval) {
        clearInterval(state.refreshInterval)
        state.refreshInterval = null
      }
    },
    setRequestInProgress: (state, action: PayloadAction<boolean>) => {
      state.requestInProgress = action.payload
    },
    setRangeMin: (state, action: PayloadAction<{name: string, value: number}>) => {
      state.ranges[action.payload.name].min = action.payload.value
    },
    setRangeMax: (state, action: PayloadAction<{name: string, value: number}>) => {
      state.ranges[action.payload.name].max = action.payload.value
    },
    reset: (state) => {
      state.therapy = null
    },
    setAutoScale: (state, action: PayloadAction<boolean>) => {
      state.autoScale = action.payload
    },
  }
})


export const {
  setTherapy, setRelative, setDate, setDirection, setRequestInProgress, setRangeMin, setRangeMax, reset
} = roxChart.actions

export const selectTherapy = (state: RootState) => state.roxChart.therapy
export const selectStats = (state: RootState) => state.roxChart.stats
export const selectRelative = (state: RootState) => state.roxChart.relative
export const selectDate = (state: RootState) => state.roxChart.date
export const selectRequestInProgress = (state: RootState) => state.deviceChart.requestInProgress
export const selectDirection = (state: RootState) => state.roxChart.direction
export const selectRanges = (state: RootState) => state.roxChart.ranges
export const selectAutoScale = (state: RootState) => state.deviceChart.autoScale

export const setTherapyAsync = (
  accessToken: string,
  account_id: string,
  device_id: string,
  patient_count: string,): AppThunk => (dispatch, getState) => {
  const {apiUrl, headers} = getHeaders(accessToken)
  const state = getState().roxChart
  dispatch(setRequestInProgress(true))
  const markers = [...Array.from(Array(6).keys())].map(e => e * (
    state.relative === 3600*2 ? 0.5 * 3600 : state.relative === 3600*6 ? 3600 : 2*3600)).join(',')
  // @ts-ignore
  fetch(`${apiUrl}/therapy/${account_id}.${device_id}.${patient_count}`
        + (state.direction === 'From' ? '?from=' : '?to=') + state.date
        + `&markers=${markers}`
        + `&bin=${state.bin}`
        + `&relative=${state.relative}`, {
    method: 'GET',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then((data) => {
      dispatch(setRequestInProgress(false))
      dispatch(setTherapy({therapy: data.data, stats: data.stats}))
    })
    .catch((error) => handleError(dispatch, error))
}

export default roxChart.reducer;
