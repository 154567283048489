import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import {User} from "../../../domain/User";
import {setListAsync} from "../list/usersSlice";
import {getHeaders, handleError, handleResponse} from "../../../utils/fetchUtils";

interface UserState {
  obj: User,
  message: string,
  status: string,
}

const initialState: UserState = {
  obj: null as any,
  message: '',
  status: '',
};

export interface Role {
  name: string,
  enabled: boolean,
}

export const editUserSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    newUser: (state) => {
      state.obj = {user_id: '', account_id: '', email:'', name: '', roles: '', modified: ''}
    },
    setUser: (state, action: PayloadAction<User>) => {
      state.obj = action.payload;
    },
    setEmail: (state, action: PayloadAction<string>) => {
      state.obj.email = action.payload;
    },
    setAccountId: (state, action: PayloadAction<string>) => {
      state.obj.account_id = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.obj.name = action.payload;
    },
    setRoles: (state, action: PayloadAction<string>) => {
      state.obj.roles = action.payload;
    },
    setRole: (state, action: PayloadAction<Role>) => {
      let roles = new Set(state.obj.roles ? state.obj.roles.split(',') : [])
      if (action.payload.enabled) {
        roles.add(action.payload.name)
      } else {
        roles.delete(action.payload.name)
      }
      state.obj.roles = Array.from(roles).join(',')
    },
    deleteUserState: (state) => {
      state.obj = null as any
    },
    setStatus: (state, action: PayloadAction<{ status: string, message: string }>) => {
      state.status = action.payload.status
      state.message = action.payload.message
    },
    resetStatus: (state) => {
      state.status = ''
      state.message = ''
    },
  },
});

export const {newUser, setUser, setEmail, setAccountId, setName,
  setRoles, setRole, deleteUserState, setStatus, resetStatus} = editUserSlice.actions;

export const selectUser = (state: RootState) => state.user.obj;
export const selectMessage = (state: RootState) => state.user.message;
export const selectStatus = (state: RootState) => state.user.status;

export const setUserAsync = (accessToken: string, account_id: string, user_id: string): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/users/${account_id}/${user_id}`, {
    method: 'GET',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then((data) => {
      dispatch(setUser(data))
    })
    .catch((error) => handleError(dispatch, error))
}

export const createAsync = (accessToken: string, account_id: string, obj: User): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/users/${account_id}`, {
    method: 'POST',
    headers: headers,
    body: JSON.stringify(obj)
  }).then(response => {
    if (response.ok) {
      dispatch(setStatus({status: 'ok', message: 'Done'}))
    } else if (response.status === 409) {
      response.json().then(data => {
        dispatch(setStatus({status: 'error', message: data.response.Error.Message}))
      })
    } else {
      handleResponse(dispatch, response)
    }
  })
}

export const updateAsync = (accessToken: string, account_id: string, obj: User): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/users/${account_id}/${obj.user_id}`, {
    method: 'PUT',
    headers: headers,
    body: JSON.stringify(obj)
  }).then(response => {
    if (response.ok) {
      dispatch(setStatus({status: 'ok', message: 'Done'}))
    } else if (response.status === 409) {
      response.json().then(data => {
        dispatch(setStatus({status: 'error', message: data.response.Error.Message}))
      })
    } else {
      handleResponse(dispatch, response)
    }
  })
}

export const deleteAsync = (accessToken: string, account_id: string, user_id: string): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/users/${account_id}/${user_id}`, {
    method: 'DELETE',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then(() => dispatch(setListAsync(accessToken, account_id)))
    .catch((error) => handleError(dispatch, error))
}

export default editUserSlice.reducer;
