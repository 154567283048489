import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {Device} from "../../domain/Device";

interface EditDeviceDetails {
  popup: boolean,
  locationInput: string,
  firstTherapyDateInput: string,
  requireOxygenInput: string,
  confirmedOfflineInput: boolean
  deviceTimeDrift: number,
  timeCorrection: number,
}

const initialState: EditDeviceDetails = {
  popup: false,
  locationInput: '',
  firstTherapyDateInput: '',
  requireOxygenInput: 'no',
  confirmedOfflineInput: false,
  deviceTimeDrift: 0,
  timeCorrection: 0,
}

export const editDeviceDetails = createSlice({
  name: 'device',
  initialState,
  reducers: {
    editDeviceDetailsPopup: (state, action: PayloadAction<Device>) => {
      state.popup = true
      state.locationInput = action.payload.location
      state.firstTherapyDateInput = action.payload.first_therapy_date
      state.requireOxygenInput = action.payload.require_oxygen
      state.confirmedOfflineInput = action.payload.confirmed_offline
      state.deviceTimeDrift = action.payload.device_time_drift
      state.timeCorrection = action.payload.time_correction
    },
    closePopup: (state) => {
      state.popup = false
    },
    setLocationInput: (state, action: PayloadAction<string>) => {
      state.locationInput = action.payload
    },
    setFirstTherapyDateInput: (state, action: PayloadAction<string>) => {
      state.firstTherapyDateInput = action.payload
    },
    setRequireOxygenInput: (state, action: PayloadAction<string>) => {
      state.requireOxygenInput = action.payload
    },
    setConfirmedOfflineInput: (state, action: PayloadAction<boolean>) => {
      state.confirmedOfflineInput = action.payload
    },
    resetTimeCorrection: (state) => {
      state.timeCorrection = 0
    },
  }
})

export const {
  editDeviceDetailsPopup, closePopup,
  setLocationInput, setFirstTherapyDateInput,
  setRequireOxygenInput, setConfirmedOfflineInput,
  resetTimeCorrection
} = editDeviceDetails.actions

export const selectPopup = (state: RootState) => state.editDeviceDetails.popup
export const selectLocationInput = (state: RootState) => state.editDeviceDetails.locationInput
export const selectFirstTherapyDateInput = (state: RootState) => state.editDeviceDetails.firstTherapyDateInput
export const selectRequireOxygenInput = (state: RootState) => state.editDeviceDetails.requireOxygenInput
export const selectConfirmedOfflineInput = (state: RootState) => state.editDeviceDetails.confirmedOfflineInput
export const selectDeviceTimeDrift = (state: RootState) => state.editDeviceDetails.deviceTimeDrift
export const selectTimeCorrection = (state: RootState) => state.editDeviceDetails.timeCorrection

export default editDeviceDetails.reducer;
