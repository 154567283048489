import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import {setLoggedIn} from '../../authorization/authorizationSlice';
import {Account} from "../../../domain/Account";
import {getHeaders, handleError, handleResponse} from "../../../utils/fetchUtils";


interface AccountsState {
  list: Account[],
}

const initialState: AccountsState = {
  list: null as any,
};


export const accountsSlice = createSlice({
  name: 'accounts',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<Account[]>) => {
      state.list = action.payload;
    },
    deleteAccountsState: (state) => {
      state.list = null as any
    },
  },
});

export const { setList, deleteAccountsState } = accountsSlice.actions;

export const selectList = (state: RootState) => state.accounts.list;

export const setListAsync = (accessToken: string): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/accounts?name=`, {
    method: 'GET',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then((data) => {
      dispatch(setList(data));
    })
    .catch((error) => handleError(dispatch, error))
}


export default accountsSlice.reducer;
