import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectAccessToken} from "../authorization/authorizationSlice";
import {
  Button, Form, FormGroup, Label,
  Modal, ModalBody, ModalFooter, ModalHeader,
} from "reactstrap";
import { useParams } from 'react-router-dom'
import {
  closePopup,
  selectPopup,
} from "./removePatientSlice";
import {updateDeviceAsync} from "./updateDevice";

interface RemovePatientProps {
  onSave: any,
}

export function RemovePatient(props: RemovePatientProps) {
  const { account_id, device_id } = useParams() as any
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken)
  const popup = useSelector(selectPopup)

  return (
    <Modal isOpen={popup} >
      <ModalHeader>Remove Patient</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label>Confirm remove patient</Label>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => {
          dispatch(updateDeviceAsync(accessToken, account_id, device_id, {
            patient_name: '',
            patient_date_of_birth: '',
            patient_gender: '',
            patient_id: '',
            other_patient_id: '',
            doctor_user_id: '',
            clinical_notes: '',
          }, 'remove'))
          props.onSave()
          dispatch(closePopup())
        }}>Remove</Button>
        <Button color="secondary" onClick={() => dispatch(closePopup())}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}
