import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectEmail} from '../me/meSlice';
import {Button, Form, FormGroup, Input, Label, Spinner} from "reactstrap";
import {siteTitlePrefix} from "../../App";
import {
  changePasswordAsync,
  confirmPasswordAsync, selectChangePasswordState,
  selectCode, selectCodeStatus, selectMessage,
  selectPassword,
  setCode, setCodeStatus,
  setPassword,
} from "./changePasswordSlice";
import {selectAccessToken} from "../authorization/authorizationSlice";
import { Alert } from 'reactstrap';
import {Progress} from "../progress/progress";
import {selectProgress} from "../progress/progressSlice";


export function ChangePassword() {
  const dispatch = useDispatch()
  const email = useSelector(selectEmail);
  const codeStatus = useSelector(selectCodeStatus)
  const message = useSelector(selectMessage)
  const code = useSelector(selectCode);
  const password = useSelector(selectPassword)
  const title = 'Change Password'
  const accessToken = useSelector(selectAccessToken)
  const changePasswordState = useSelector(selectChangePasswordState)
  const progress = useSelector(selectProgress)

  useEffect(() => {
    document.title = siteTitlePrefix + title
  }, []);

  return email == null ? (<Spinner animation="border" className="m-5"/>) : (
    <>
      <Progress/>
      <h1>
        {title}
      </h1>
      <Form>
        <FormGroup>
          <Label for="email">Email</Label>
          <Input
            name="email"
            id="email"
            placeholder="Email"
            value={email}
            disabled
          />
        </FormGroup>
        <FormGroup className=''>
          {!codeStatus && <Button className="m-2"
                  onClick={() => dispatch(changePasswordAsync(accessToken))}>
            Send Code
          </Button>}
          <Alert color="info" isOpen={codeStatus === 'sent'} toggle={() => {dispatch(setCodeStatus(null as any))}}>
            {message}
          </Alert>
          <Alert color="danger" isOpen={codeStatus === 'error'} toggle={() => {dispatch(setCodeStatus(null as any))}}>
            {message}
          </Alert>
          <Alert color="secondary" isOpen={codeStatus === 'sending'} >
            Requesting code...
          </Alert>
        </FormGroup>
        <FormGroup>
          <Label for="email">Code</Label>
          <Input
            name="code"
            id="code"
            placeholder="Code"
            value={code}
            onChange={e => dispatch(setCode(e.target.value))}
          />
        </FormGroup>
        <FormGroup>
          <Label for="email">Password</Label>
          <Input
            name="password"
            id="password"
            placeholder="Password"
            value={password}
            onChange={e => dispatch(setPassword(e.target.value))}
            type='password'
          />
        </FormGroup>
        <FormGroup>
          <Button className="m-2" disabled={progress.status !== ''}
                  onClick={() => dispatch(confirmPasswordAsync(accessToken, changePasswordState))}>
            Set Password
          </Button>
        </FormGroup>
      </Form>
    </>
  );
}
