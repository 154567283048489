import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectAccessToken} from "../authorization/authorizationSlice";
import {
  Button, Form, FormGroup, Input, Label,
  Modal, ModalBody, ModalFooter, ModalHeader,
} from "reactstrap";
import { useParams } from 'react-router-dom'
import {
  closePopup,
  selectPopup,
  selectStaffList,
  selectStaffUserIdInput,
  setStaffUserIdInput} from "./changeCareContactSlice";
import {updateDeviceAsync} from "./updateDevice";

interface ChangeCareContactProps {
  onSave: any,
}

export function ChangeCareContact(props: ChangeCareContactProps) {
  const { account_id, device_id } = useParams() as any
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken)
  const popup = useSelector(selectPopup)
  const staffUserIdInput = useSelector(selectStaffUserIdInput)
  const staffList = useSelector(selectStaffList)

  return (
    <Modal isOpen={popup} >
      <ModalHeader>Change care contact</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="patient_name">Care contact</Label>
            <Input
              type="select"
              name="staff"
              id="staff"
              placeholder="Care contact"
              value={staffUserIdInput}
              onChange={e => dispatch(setStaffUserIdInput(e.target.value))}
            >
              <option key='-1' value=''>(unassigned)</option>
              {staffList.map((item, index) => (
                <option key={index} value={item.user_id}>{item.name}</option>
              ))}
            </Input>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => {
          dispatch(updateDeviceAsync(accessToken, account_id, device_id, {
            staff_user_id: staffUserIdInput,
          }))
          props.onSave()
          dispatch(closePopup())
        }}>Save</Button>
        <Button color="secondary" onClick={() => dispatch(closePopup())}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}
