import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectDoctorList,
  selectPatient, selectStaffList,
  setPastPatientAsync, setUserListsAsync,
} from './patientStateSlice';
import {selectAccessToken} from "../../authorization/authorizationSlice";
import {
  Breadcrumb, BreadcrumbItem, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar,
  Spinner, UncontrolledDropdown,
} from "reactstrap";
import {useNavigate, useParams} from 'react-router-dom'
import {Patient, SystemStatus} from "../../../domain/Patient";
import {siteTitlePrefix} from "../../../App";
import styles from './PatientState.module.css'
import {DataElement, DataElementList} from "../../../components/DataElement";
import {DeviceChart} from "../../devices/chart/DeviceChart";


export function PastPatientState() {
  const { account_id, patient_id } = useParams() as any
  const obj : Patient = useSelector(selectPatient);
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken)
  const title = `Prior patient`
  const patient = useSelector(selectPatient)
  const staffList = useSelector(selectStaffList)
  const doctorList = useSelector(selectDoctorList)
  const navigate = useNavigate()

  useEffect(() => {
    document.title = siteTitlePrefix + title
  }, []);

  useEffect(() => {
    dispatch(setUserListsAsync(accessToken, account_id))
    dispatch(setPastPatientAsync(accessToken, account_id, patient_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return obj == null ? (<Spinner animation="border" className="m-5"/>) : (
    <>
      <h1>{title}</h1>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/">Home</BreadcrumbItem>
        <BreadcrumbItem tag="a" href={`/past/patients/${account_id}`}>Prior patients</BreadcrumbItem>
        <BreadcrumbItem active tag="span">
          {patient_id}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className={styles.patientState}>
        <Navbar className='float-end m-0 p-0'>
          <Nav>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Actions
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => navigate(`/past/patients/${account_id}/${patient_id}/chart`)}>
                  View chart details
                </DropdownItem>
                <DropdownItem disabled>
                  View ROX chart
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Navbar>
        <DataElement title='date' value={patient.device_date}/>
        <DataElement title='model' value={patient.model}/>
        <DataElement title='firmware version' value={patient.firmware_version}/>
        <DataElement title='connectivity module' value={patient.connection_id}/>
        {'timezone' in patient && <DataElement title='timezone' value={patient.timezone}/>}
        {'location' in patient && <DataElement title='location' value={patient.location}/>}
        {'patient_name' in patient && <DataElement title='patient' value={patient.patient_name}/>}
        <br/>
        <DataElement title='system status' value={SystemStatus[patient.system_status]}/>
        <DataElementList title='alarm codes' value={patient.alarm_codes}/>
        <DataElement title='treatment time' value={patient.user_therapy_time}/>
        <DataElement title='total therapy time' value={patient.total_therapy_time}/>
        <DataElement title='patient count' value={patient.patient_count}/>
        <DataElement title='first therapy date' value={patient.first_therapy_date}/>
        <DataElement title='require oxygen' value={patient.require_oxygen}/>
        <br/>
        <DataElement title='date of birth' value={patient.patient_date_of_birth}/>
        <DataElement title='gender' value={patient.patient_gender}/>
        <DataElement title='patient id' value={patient.patient_id}/>
        <DataElement title='other patient id' value={patient.other_patient_id}/>
        <DataElement title='referral doctor' value={
          doctorList.filter(item => item.user_id === patient.doctor_user_id).map(item => item.name)
        }/>
        <DataElement title='care contact' value={
          staffList.filter(item => item.user_id === patient.staff_user_id).map(item => item.name)
        }/>
        <DataElement title='clinical_notes' value={patient.clinical_notes}/>
      </div>
      <DeviceChart chartType='single' embedded={true}/>
    </>
  );
}
