import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {Device} from "../../domain/Device";
import {User} from "../../domain/User";

interface ChangeCareContact {
  popup: boolean,
  staffUserIdInput: string,
  staffList: User[],
}

const initialState: ChangeCareContact = {
  popup: false,
  staffUserIdInput: '',
  staffList: [],
}

export const changeCareContact = createSlice({
  name: 'device',
  initialState,
  reducers: {
    changeCareContactPopup: (state, action: PayloadAction<{device: Device, staffList: User[]}>) => {
      state.popup = true
      state.staffUserIdInput = action.payload.device.staff_user_id
      state.staffList = action.payload.staffList
    },
    closePopup: (state) => {
      state.popup = false
    },
    setStaffUserIdInput: (state, action: PayloadAction<string>) => {
      state.staffUserIdInput = action.payload
    },
  }
})

export const {changeCareContactPopup, closePopup, setStaffUserIdInput } = changeCareContact.actions

export const selectPopup = (state: RootState) => state.changeCareContact.popup
export const selectStaffUserIdInput = (state: RootState) => state.changeCareContact.staffUserIdInput
export const selectStaffList = (state: RootState) => state.changeCareContact.staffList

export default changeCareContact.reducer;
