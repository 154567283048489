import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectList,
  setListAsync,
} from './devicesSlice';
import {selectAccessToken} from "../../authorization/authorizationSlice";
import {Breadcrumb, BreadcrumbItem, Spinner, Table} from "reactstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import {SystemStatus} from "../../../domain/Device";
import styles from './Devices.module.css';
import {AutoRefresh} from "../../../components/AutoRefresh";
import moment from "moment";
import {siteTitlePrefix} from "../../../App";

export function Devices() {
  const { account_id } = useParams() as any
  const devices = useSelector(selectList)
  const list = devices[account_id]
  const dispatch = useDispatch()
  const accessToken = useSelector(selectAccessToken)
  const navigate = useNavigate()
  const title = 'Devices'

  useEffect(() => {
    document.title = siteTitlePrefix + title
    dispatch(setListAsync(accessToken, account_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return list == null ? (<Spinner animation="border" className="m-5"/>) : (
    <>
      <h1>{title}</h1>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/">Home</BreadcrumbItem>
        <BreadcrumbItem active tag="span">{title}</BreadcrumbItem>
      </Breadcrumb>

      <AutoRefresh callback={() => dispatch(setListAsync(accessToken, account_id))} timeout={2000} className="float-end mt-3"/>
      <div className="tab-toggler">
        <button type="button" className="btn btn-dark m-1"
                onClick={() => navigate(`/current/devices/${account_id}`)}>Current</button>
        <button type="button" className="btn btn-light m-1"
                onClick={() => navigate(`/past/devices/${account_id}`)}>Prior</button>
      </div>
      <Table striped bordered hover>
      <thead>
        <tr>
          <th className={styles.alertHead}>
            <svg className="bi" width="20" height="20" fill="currentColor">
              <use xlinkHref="/bootstrap-icons.svg#bell"/>
            </svg>
          </th>
          <th>Serial number</th>
          <th>First therapy date</th>
          <th>Status</th>
          <th>Location</th>
          <th>Online</th>
          <th>Last therapy session</th>
          <th>Treatment time</th>
        </tr>
      </thead>
      <tbody>
      {list.map(device => (
        <tr key={device.device_id}>
          <td>
            {device.alarm !== '0' &&
              <svg className="bi" width="20" height="20" fill="currentColor">
                <use xlinkHref="/bootstrap-icons.svg#bell-fill"/>
              </svg>
              }
          </td>
          <td>
            <Link to={`/devices/${account_id}/${device.device_id}/-`}>{device.serial_number || 'N/A'}</Link>
          </td>
          <td>{device.first_therapy_date}</td>
          <td>{SystemStatus[device.system_status || '0']}</td>
          <td>{device.location}</td>
          <td>{device.online ? 'online': 'offline'}</td>
          <td>{device.therapy_sessions ? moment.utc(device.therapy_sessions.split(',').pop()).local().format('LLL'): ''}</td>
          <td>{device.total_therapy_time}</td>
        </tr>
      ))}
      </tbody>
    </Table>
    </>
  );
}
