import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectAccessToken} from "../../authorization/authorizationSlice";
import {useLocation, useParams} from 'react-router-dom'
import {siteTitlePrefix} from "../../../App";
import styles from './RoxChart.module.css';
import {
  reset, selectRelative,
  selectTherapy,
  setTherapy,
  setTherapyAsync,
  setRelative, selectDate, setDate, selectDirection, setDirection, selectRanges,
  setRangeMax, setRangeMin, selectStats
} from "./roxChartSlice";
import { Line, XAxis, YAxis, CartesianGrid,  ReferenceLine, ComposedChart, Scatter, LabelList } from 'recharts';
import {Input, Label, Spinner} from "reactstrap";
import Datetime from 'react-datetime';
import "react-datetime/css/react-datetime.css";
import moment from "moment";
import {round} from "lodash";
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import {selectAutoScale, setAutoScale} from "./deviceChartSlice";
import {formatInt} from "../../../utils/formatUtils";


export function RoxChart() {
  const {account_id, device_id, patient_count} = useParams() as any
  const title = `Rox Chart`
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken)
  const location = useLocation()
  const parent = location.pathname.includes('/devices/') ? 'devices' : 'patients'
  const therapy = useSelector(selectTherapy)
  const stats = useSelector(selectStats)
  const respiratory_rate_stat = stats ? stats.respiratory_rate : {min: NaN, max: NaN, avg: NaN, last: NaN}
  const spO2_stat = stats ? stats.spO2 : {min: NaN, max: NaN, avg: NaN, last: NaN}
  const rox_stat = stats ? stats.rox : {min: NaN, max: NaN, avg: NaN, last: NaN}

  const direction = useSelector(selectDirection)
  const markers = therapy && Object.entries(therapy)
    .map(([therapy_session, records]) => records
      .filter(record => record.marker !== null)).flat()
  const startMarker = markers && markers[0] ? moment(markers[0].timestamp).format('LLL') : ''
  const relative = useSelector(selectRelative)
  const date = useSelector(selectDate)
  const autoScale = useSelector(selectAutoScale)

  const refresh = (action: any) => {
    if (action) {
      dispatch(action)
    } else {
      dispatch(setTherapy(null))
    }
    dispatch(setTherapyAsync(accessToken, account_id, device_id, patient_count))
  }
  const ROX = 4.88
  const SPO2 = 95
  const fiO2 = [0.4, 0.5, 0.6, 0.7, 0.8, 0.9]
  const rr = fiO2.map(v => (SPO2/v)/ROX)

  const formatTimeMarkers = (value: string, includeUnits: boolean) => relative/3600 === 2
    ? round(parseFloat(value)*6)*10 + (includeUnits ? 'min' : '')
    : round(parseFloat(value),1) + (includeUnits ? 'hr' : '')

  const timeTicks = relative/3600 === 4 ? 12: relative/3600 === 6 ? 7 : 13;
  const ranges = useSelector(selectRanges)

  const data = fiO2.map(v => {
    return {x: v, y: (SPO2/v)/ROX, value: 0}
  })

  useEffect(() => {
    document.title = siteTitlePrefix + title
    refresh(null)
    return () => {
      dispatch(reset())
    }
  }, [dispatch]);

  // @ts-ignore
  return (
    <>
      <>
        <h1>{title}</h1>
        <Breadcrumb tag="nav">
          <BreadcrumbItem tag="a" href="/">Home</BreadcrumbItem>
          <BreadcrumbItem tag="a" href={`/current/${parent}/${account_id}`}>{parent === 'devices' ? 'Devices' : 'Patients'}</BreadcrumbItem>
          <BreadcrumbItem tag="a" href={`/${parent}/${account_id}/${device_id}/${patient_count}`}>{device_id}</BreadcrumbItem>
          <BreadcrumbItem active tag="span">
            {title}
            <svg role="button" className="bi mx-2" width="20" height="20" fill="currentColor" onClick={() => refresh(null)}>
              <use xlinkHref="/bootstrap-icons.svg#repeat"/>
            </svg>
          </BreadcrumbItem>
        </Breadcrumb>
      </>
      <div className={styles.timeRangeToggler}>
        <Label for="autoScale">Auto scale</Label>
        <Input
          className="mx-lg-2"
          name="autoScale"
          id="autoScale"
          type="checkbox"
          checked={autoScale}
          onChange={e => dispatch(setAutoScale(e.target.checked))}
        />
        <button type="button" className={relative === 3600*2 ? "btn btn-dark m-1" : "btn btn-light m-1"}
                onClick={() => refresh(setRelative({relative:3600*2, bin:10*60}))}>2 hours</button>
        <button type="button" className={relative === 3600*6 ? "btn btn-dark m-1" : "btn btn-light m-1"}
                onClick={() => refresh(setRelative({relative:3600*6, bin:30*60}))}>6 hours</button>
        <button type="button" className={relative === 3600*12 ? "btn btn-dark m-1" : "btn btn-light m-1"}
                onClick={() => refresh(setRelative({relative:3600*12, bin:60*60}))}>12 hours</button>
        <Input
          type="select"
          id="direction"
          value={direction}
          onChange={e => refresh(setDirection(e.target.value))}
          className={styles.direction}
        >
          <option key='1' value='From'>From</option>
          <option key='2' value='Before'>Before</option>
        </Input>
        <Datetime className="d-inline-block mx-2" value={moment(date)} onChange={e => refresh(setDate(moment(e).utc().format()))}/>
      </div>
      <h3>ROX vs. Time</h3>
      {
        !(therapy && markers) ? (<div className="align-items-center w-75 m-5">
          <Spinner animation="border" className="m-5"/>
        </div>) : (<>
          <h6>Therapy session started at {startMarker}</h6>
          <table>
            <tr>
              <td width={100} className="align-top">
                <div className={styles.rangeControl}>
                  <Label for="timeYMax" className="small">Max</Label>
                  <Input id="timeYMax"
                         bsSize="sm"
                         className="mb-2"
                         size={3}
                         disabled={autoScale}
                         value={ranges['timeY'].max}
                         onChange={e => dispatch(setRangeMax({name: 'timeY', value: parseInt(e.target.value) || 0 }))}
                  />
                  <Label for="timeYMin" className="small">Min</Label>
                  <Input id="timeYMin"
                         bsSize="sm"
                         size={3}
                         disabled={autoScale}
                         value={ranges['timeY'].min}
                         onChange={e => dispatch(setRangeMin({name: 'timeY', value: parseInt(e.target.value) || 0 }))}
                  />
                  <hr/>
                  <div className={styles.legend}>
                    <h4>ROX</h4>
                    <div className={styles.stats_value} >
                      {formatInt(rox_stat.last)}
                    </div>
                    <div className={styles.stats_minmax}>
                      {formatInt(rox_stat.max)}
                      <br/>
                      {formatInt(rox_stat.min)}
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <ComposedChart width={1000} height={600}>
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis type='number'
                         height={60}
                         domain={[0, relative/3600]}
                         label={`Time from ${startMarker}`}
                         tickFormatter={(value: string) => formatTimeMarkers(value, false)}
                         tickCount={timeTicks}
                         interval={0}
                         dataKey="hours"
                         padding='no-gap'
                         allowDataOverflow={true}/>
                  <YAxis type='number'
                         domain={[ranges['timeY'].min, ranges['timeY'].max]}
                         tickCount={13}
                         interval={0}
                         allowDataOverflow={true}/>
                  {
                    Object.entries(therapy).map(([therapy_session, value]) => (
                      <Line key={therapy_session} type="monotone" dataKey="rox" data={value} stroke="#5a98cb" dot={false}/>
                    ))
                  }
                  <Scatter type='monotone' dataKey="rox" data={markers} stroke="#5a98cb" fill="blue">
                    <LabelList dataKey="hours" position="top" formatter={(value: string) => formatTimeMarkers(value, true)}/>
                  </Scatter>
                  <ReferenceLine y={4.88} stroke="red" strokeDasharray="3 3" />
                </ComposedChart>
              </td>
            </tr>
            <tr>
              <td></td>
              <td></td>
            </tr>
          </table>
          <h3>SpO2 vs ROX</h3>
          <h6>Therapy session started at {startMarker}</h6>
          <table>
            <tr>
              <td width={100} className="align-top">
                <div className={styles.rangeControl}>
                  <Label for="spO2YMax" className="small">Max</Label>
                  <Input id="spO2YMax"
                         bsSize="sm"
                         className="mb-2"
                         size={3}
                         disabled={autoScale}
                         value={ranges['spO2Y'].max}
                         onChange={e => dispatch(setRangeMax({name: 'spO2Y', value: parseInt(e.target.value) || 0 }))}
                  />
                  <Label for="spO2YMin" className="small">Min</Label>
                  <Input id="spO2YMin"
                         bsSize="sm"
                         size={3}
                         disabled={autoScale}
                         value={ranges['spO2Y'].min}
                         onChange={e => dispatch(setRangeMin({name: 'spO2Y', value: parseInt(e.target.value) || 0 }))}
                  />
                  <hr/>
                  <div className={styles.legend}>
                    <h4>SpO2</h4>
                    <div className={styles.stats_value} >
                      {formatInt(spO2_stat.last)}
                    </div>
                    <div className={styles.stats_minmax}>
                      {formatInt(spO2_stat.max)}
                      <br/>
                      {formatInt(spO2_stat.min)}
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <ComposedChart width={1000} height={600} >
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis type='number'
                         height={60} domain={[ranges['spO2X'].min, ranges['spO2X'].max]}
                         tickCount={13}
                         interval="preserveStartEnd"
                         dataKey="rox"
                         padding='no-gap'
                         allowDataOverflow={true}
                         label="ROX"/>
                  <YAxis type='number'
                         domain={[ranges['spO2Y'].min, ranges['spO2Y'].max]}
                         tickCount={16}
                         allowDataOverflow={true}/>
                  {
                    Object.entries(therapy).map(([therapy_session, value]) => (
                      <Line key={therapy_session} type="monotone" dataKey="spO2" data={value} stroke="#5a98cb" dot={false}/>
                    ))
                  }
                  <Scatter type='monotone' dataKey="spO2" data={markers} stroke="#5a98cb" fill="blue">
                    <LabelList dataKey="hours" position="top" formatter={(value: string) => formatTimeMarkers(value, true)}/>
                  </Scatter>
                  <ReferenceLine x={4.88} stroke="red" strokeDasharray="3 3" />
                </ComposedChart>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div className={styles.rangeControlX}>
                  <Label for="spO2XMin" className="small">Min</Label>
                  <Input id="spO2XMin"
                         bsSize="sm"
                         size={3}
                         disabled={autoScale}
                         value={ranges['spO2X'].min}
                         onChange={e => dispatch(setRangeMin({name: 'spO2X', value: parseInt(e.target.value) || 0 }))}
                  />
                  <Label for="spO2XMax" className="small">Max</Label>
                  <Input id="spO2XMax"
                         bsSize="sm"
                         className="mb-2"
                         size={3}
                         disabled={autoScale}
                         value={ranges['spO2X'].max}
                         onChange={e => dispatch(setRangeMax({name: 'spO2X', value: parseInt(e.target.value) || 0 }))}
                  />
                </div>
              </td>
            </tr>
          </table>
          <h3>RR vs ROX</h3>
          <h6>Therapy session started at {startMarker}</h6>
          <table>
            <tr>
              <td width={100} className="align-top">
                <div className={styles.rangeControl}>
                  <Label for="rrYMax" className="small">Max</Label>
                  <Input id="rrYMax"
                         bsSize="sm"
                         className="mb-2"
                         size={3}
                         disabled={autoScale}
                         value={ranges['rrY'].max}
                         onChange={e => dispatch(setRangeMax({name: 'rrY', value: parseInt(e.target.value) || 0 }))}
                  />
                  <Label for="rrYMin" className="small">Min</Label>
                  <Input id="rrYMin"
                         bsSize="sm"
                         size={3}
                         disabled={autoScale}
                         value={ranges['rrY'].min}
                         onChange={e => dispatch(setRangeMin({name: 'rrY', value: parseInt(e.target.value) || 0 }))}
                  />
                  <hr/>
                  <div className={styles.legend}>
                    <h4>RR</h4>
                    <div className={styles.stats_value} >
                      {formatInt(respiratory_rate_stat.last)}
                    </div>
                    <div className={styles.stats_minmax}>
                      {formatInt(respiratory_rate_stat.max)}
                      <br/>
                      {formatInt(respiratory_rate_stat.min)}
                    </div>
                  </div>
                </div>
              </td>
              <td>
                <ComposedChart width={1000} height={600} >
                  <CartesianGrid strokeDasharray="3 3"/>
                  <XAxis type='number'
                         height={60}
                         domain={[ranges['rrX'].min, ranges['rrX'].max]}
                         tickCount={13}
                         interval="preserveStartEnd"
                         dataKey="rox"
                         padding='no-gap'
                         allowDataOverflow={true}
                         label="ROX"/>
                  <YAxis type='number'
                         domain={[ranges['rrY'].min, ranges['rrY'].max]}
                         tickCount={16}
                         allowDataOverflow={true}/>
                  {
                    Object.entries(therapy).map(([therapy_session, value]) => (
                      <Line key={therapy_session} type="monotone" dataKey="respiratory_rate" data={value} stroke="#5a98cb" dot={false}/>
                    ))
                  }
                  <Scatter type='monotone' dataKey="respiratory_rate" data={markers} stroke="#5a98cb" fill="blue">
                    <LabelList dataKey="hours" position="top" formatter={(value: string) => formatTimeMarkers(value, true)}/>
                  </Scatter>
                  <ReferenceLine x={4.88} stroke="red" strokeDasharray="3 3" />
                </ComposedChart>
              </td>
            </tr>
            <tr>
              <td></td>
              <td>
                <div className={styles.rangeControlX}>
                  <Label for="rrXMin" className="small">Min</Label>
                  <Input id="rrXMin"
                         bsSize="sm"
                         size={3}
                         disabled={autoScale}
                         value={ranges['rrX'].min}
                         onChange={e => dispatch(setRangeMin({name: 'rrX', value: parseInt(e.target.value) || 0 }))}
                  />
                  <Label for="rrXMax" className="small">Max</Label>
                  <Input id="rrXMax"
                         bsSize="sm"
                         className="mb-2"
                         size={3}
                         disabled={autoScale}
                         value={ranges['rrX'].max}
                         onChange={e => dispatch(setRangeMax({name: 'rrX', value: parseInt(e.target.value) || 0 }))}
                  />
                </div>
              </td>
            </tr>
          </table>
        </>)}
    </>
  )
}

