import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import {getHeaders, handleError, handleResponse} from "../../../utils/fetchUtils";
import {User} from "../../../domain/User";
import {Device} from "../../../domain/Device";

interface PatientState {
  obj: Device,
  staffList: User[],
  doctorList: User[],
}

const initialState: PatientState = {
  obj: null as any,
  staffList: [],
  doctorList: [],
}


export const patientState = createSlice({
  name: 'patient',
  initialState,
  reducers: {
    setPatient: (state, action: PayloadAction<Device>) => {
      state.obj = action.payload
    },
    setUserLists: (state, action: PayloadAction<User[]>) => {
      state.staffList = action.payload.filter(user => user.roles.includes('staff'));
      state.doctorList = action.payload.filter(user => user.roles.includes('doctor'));
    },
    reset: (state) => {
      state.obj = null as any
    },
  }
})

export const {
  setPatient, setUserLists, reset,
} = patientState.actions

export const selectPatient = (state: RootState) => state.patientState.obj
export const selectStaffList = (state: RootState) => state.patientState.staffList
export const selectDoctorList = (state: RootState) => state.patientState.doctorList

export const setPatientAsync = (accessToken: string, account_id: string, device_id: string): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/devices/${account_id}/${device_id}`, {
    method: 'GET',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then((data) => {
      dispatch(setPatient(data))
    })
    .catch((error) => handleError(dispatch, error))
}

export const setPastPatientAsync = (accessToken: string, account_id: string, patient_id: string): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/patients/${account_id}/${patient_id}`, {
    method: 'GET',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then((data) => {
      dispatch(setPatient(data))
    })
    .catch((error) => handleError(dispatch, error))
}

export const setUserListsAsync = (accessToken: string, account_id: string): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/users/${account_id}`, {
    method: 'GET',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then((users) => {
      dispatch(setUserLists(users));
    })
    .catch((error) => handleError(dispatch, error))
}

export default patientState.reducer;
