import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {Device} from "../../domain/Device";

interface CorrectTimeDriftSlice {
  popup: boolean,
  deviceTimeDrift: number,
  timeCorrection: number,
  deviceId: string,
}

const initialState: CorrectTimeDriftSlice = {
  popup: false,
  deviceTimeDrift: 0,
  timeCorrection: 0,
  deviceId: ''
}

export const correctTimeDrift = createSlice({
  name: 'correctTimeDrift',
  initialState,
  reducers: {
    correctTimeDriftPopup: (state, action: PayloadAction<Device>) => {
      state.popup = true
      state.deviceTimeDrift = action.payload.device_time_drift
      state.timeCorrection = action.payload.time_correction
      state.deviceId = action.payload.device_id
    },
    closePopup: (state) => {
      state.popup = false
    },
  }
})

export const {
  correctTimeDriftPopup,
  closePopup,
} = correctTimeDrift.actions

export const selectPopup = (state: RootState) => state.correctTimeDrift.popup
export const selectDeviceTimeDrift = (state: RootState) => state.correctTimeDrift.deviceTimeDrift
export const selectTimeCorrection = (state: RootState) => state.correctTimeDrift.timeCorrection
export const selectDeviceId = (state: RootState) => state.correctTimeDrift.deviceId

export default correctTimeDrift.reducer;
