import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectAccessToken} from "../authorization/authorizationSlice";
import {
  Button, Form, FormGroup, Input, Label,
  Modal, ModalBody, ModalFooter, ModalHeader,
} from "reactstrap";
import {
  closePopup, selectDeviceId,
  selectPopup,
  selectDeviceTimeDrift, selectTimeCorrection,
} from "./correctTimeDriftSlice";
import {updateDeviceAsync} from "./updateDevice";
// @ts-ignore
import moment from "moment/moment";

interface CorrectTimeDriftProps {
  onSave: any,
  accountId: string
}

export function CorrectTimeDrift(props: CorrectTimeDriftProps) {
  const account_id = props.accountId
  const device_id = useSelector(selectDeviceId)
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken)
  const popup = useSelector(selectPopup)
  const deviceTimeDrift = useSelector(selectDeviceTimeDrift)
  const timeCorrection = useSelector(selectTimeCorrection)

  return (
    <Modal isOpen={popup} >
      <ModalHeader>Correct time drift</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="location">Device time drift</Label>
            <Input
              name="deviceTimeDrift"
              id="deviceTimeDrift"
              placeholder="Device time drift"
              value={moment.duration(deviceTimeDrift, 'seconds').humanize()}
              disabled={true}
            />
            <Label for="location">Time correction</Label>
            <Input
              name="timeCorrection"
              id="timeCorrection"
              placeholder="Time correction"
              value={moment.duration(timeCorrection, 'seconds').humanize()}
              disabled={true}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => {
          dispatch(updateDeviceAsync(accessToken, account_id, device_id,
            {time_correction: Math.round(deviceTimeDrift) + Number(timeCorrection)}))
          props.onSave()
          dispatch(closePopup())
        }}>Confirm</Button>
        <Button color="secondary" onClick={() => dispatch(closePopup())}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}
