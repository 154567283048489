import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectAccessToken} from "../authorization/authorizationSlice";
import {
  Button, Col, Form, FormGroup, Input, Label,
  Modal, ModalBody, ModalFooter, ModalHeader, Row,
} from "reactstrap";
import { useParams } from 'react-router-dom'
import {
  closePopup, resetTimeCorrection,
  selectConfirmedOfflineInput,
  selectDeviceTimeDrift,
  selectFirstTherapyDateInput,
  selectLocationInput,
  selectPopup,
  selectRequireOxygenInput,
  selectTimeCorrection,
  setConfirmedOfflineInput,
  setFirstTherapyDateInput,
  setLocationInput,
  setRequireOxygenInput
} from "./editDeviceDetailsSlice";
import {updateDeviceAsync} from "./updateDevice";
import moment from "moment";

interface EditDeviceDetailsProps {
  onSave: any,
}

export function EditDeviceDetails(props: EditDeviceDetailsProps) {
  const { account_id, device_id } = useParams() as any
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken)
  const popup = useSelector(selectPopup)
  const locationInput = useSelector(selectLocationInput)
  const firstTherapyDateInput = useSelector(selectFirstTherapyDateInput)
  const requireOxygenInput = useSelector(selectRequireOxygenInput)
  const confirmedOfflineInput = useSelector(selectConfirmedOfflineInput)
  const deviceTimeDrift = useSelector(selectDeviceTimeDrift)
  const timeCorrection = useSelector(selectTimeCorrection)

  return (
    <Modal isOpen={popup} >
      <ModalHeader>Edit details</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="firstTherapyDate">First therapy date</Label>
            <Input
              type="date"
              name="firstTherapyDate"
              id="firstTherapyDate"
              placeholder="Date"
              value={firstTherapyDateInput}
              onChange={e => dispatch(setFirstTherapyDateInput(e.target.value))}
            />
          </FormGroup>
          <FormGroup>
            <Label for="location">Device location</Label>
            <Input
              name="location"
              id="location"
              placeholder="Location"
              value={locationInput}
              onChange={e => dispatch(setLocationInput(e.target.value))}
            />
          </FormGroup>
          <FormGroup>
            <Label for="requireOxygen">Require oxygen</Label>
            <Input
              className="mx-lg-2"
              name="requireOxygen"
              id="requireOxygen"
              type="checkbox"
              placeholder="require oxygen"
              checked={requireOxygenInput === 'yes'}
              onChange={e => dispatch(setRequireOxygenInput(e.target.checked ? 'yes': 'no'))}
            />
          </FormGroup>
          <FormGroup>
            <Label for="confirmedOffline">Confirmed Offline</Label>
            <Input
              className="mx-lg-2"
              name="confirmedOffline"
              id="confirmedOffline"
              type="checkbox"
              placeholder="confirmed offline"
              checked={confirmedOfflineInput}
              onChange={e => dispatch(setConfirmedOfflineInput(e.target.checked))}
            />
          </FormGroup>
          <FormGroup className="p-2 bg-light">
            <Label for="location">Device time drift</Label>
            <Row>
              <Col md={5}>
                <Input
                  name="deviceTimeDrift"
                  id="deviceTimeDrift"
                  placeholder="Device time drift"
                  value={moment.duration(deviceTimeDrift, 'seconds').humanize()}
                  disabled={true}
                />
              </Col>
              <Col md={6}>
                <Input
                  className="w-50 d-inline-block sm"
                  name="deviceTimeDriftSec"
                  id="deviceTimeDriftSec"
                  placeholder="seconds"
                  value={`${deviceTimeDrift}s`}
                  disabled={true}
                />
              </Col>
            </Row>
            <Row>
              <Label for="timeCorrection">Time correction</Label>
              <Col xs={5}>
                <Input
                  name="timeCorrection"
                  id="timeCorrection"
                  placeholder="Time correction"
                  value={moment.duration(timeCorrection, 'seconds').humanize()}
                  disabled={true}
                />
              </Col>
              <Col xs={6}>
                <Input
                  className="w-50 d-inline-block"
                  name="timeCorrectionSec"
                  id="timeCorrectionSec"
                  placeholder="seconds"
                  value={`${timeCorrection}s`}
                  disabled={true}
                />
                <Button className="mx-2 d-inline-block btn-sm" onClick={() => dispatch(resetTimeCorrection())}>
                  Reset
                </Button>
              </Col>
            </Row>
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => {
          dispatch(updateDeviceAsync(accessToken, account_id, device_id,
            {
              location: locationInput,
              first_therapy_date: firstTherapyDateInput,
              require_oxygen: requireOxygenInput,
              confirmed_offline: confirmedOfflineInput,
              time_correction: Math.round(deviceTimeDrift) + Number(timeCorrection),
            }))
          if (confirmedOfflineInput) {
            dispatch(updateDeviceAsync(accessToken, account_id, device_id, {
              patient_name: '',
              patient_date_of_birth: '',
              patient_gender: '',
              patient_id: '',
              other_patient_id: '',
              doctor_user_id: '',
              clinical_notes: '',
            }, 'remove'))
          }
          props.onSave()
          dispatch(closePopup())
        }}>Save</Button>
        <Button color="secondary" onClick={() => dispatch(closePopup())}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}
