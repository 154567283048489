import {setLoggedIn} from "../features/authorization/authorizationSlice";
import {Dispatch} from "react";


export function getHeaders(accessToken : any) {
  const apiUrl = (window as any).env('API_URL');
  const headers = new Headers()
  headers.append('Authorization', 'Bearer ' + accessToken)
  return {apiUrl, headers}
}

export function handleResponse(dispatch : Dispatch<any>, response : Response) {
  if (response.status > 399) {
    throw response
  }
  if ((response.headers.get('content-type') || '').indexOf('json') >= 0) {
    return response.json()
  } else {
    return () => Promise.resolve({})
  }
}

export function handleError(dispatch : Dispatch<any>, error : any) {
  console.log(error)
  if (error instanceof TypeError) {
    if (error.message === 'Failed to fetch') {
      //console.log('logged off')
      //dispatch(setLoggedIn(false));
    }
  } else if (error instanceof Response) {
    console.log('Response', error)
    if (error.status === 401) {
      console.log('logged off')
      dispatch(setLoggedIn(false));
    }
  }
}


