export interface Patient {
  device_id: string,
  account_id: string,
  serial_number: string,
  connection_id: string,
  device_date: string,
  model: string,
  firmware_version: string,
  system_status: string,
  alarm: string,
  temperature: string,
  flow_setting: string,
  fiO2_setting: string,
  spO2: string,
  pulse_rate: string,
  respiratory_rate: string,
  user_therapy_time: string,
  total_therapy_time: string,
  therapy_sessions: string,
  patient_count: string,
  timezone: string,
  name: string,
  modified: string,
  timestamp: string,
  online: boolean,
  alarm_codes: string,

  patient_name: string,
  patient_date_of_birth: string,
  patient_gender: string,
  patient_id: string,
  other_patient_id: string,
  clinical_notes: string,

  location: string,
  first_therapy_date: string,
  require_oxygen: string,

  doctor_user_id: string,
  staff_user_id: string,

  epoch: number,
}

export interface Patients {
  [key: string]: Patient[]
}

export const SystemStatus : { [key: string]: string } = {
  '0': 'Provisioned',
  '1': 'Standby',
  '2': 'Therapy',
}

