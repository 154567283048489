import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import {setLoggedIn} from '../../authorization/authorizationSlice';
import {User, Users} from "../../../domain/User";
import {getHeaders, handleError, handleResponse} from "../../../utils/fetchUtils";

interface UsersPayload {
  account_id: string,
  users: User[]
}


interface UsersState {
  list: Users
}

const initialState: UsersState = {
  list: {}
};


export const usersSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<UsersPayload>) => {
      state.list[action.payload.account_id] = action.payload.users;
    },
    deleteUsersState: (state) => {
      state.list = {}
    },
  },
});

export const { setList, deleteUsersState } = usersSlice.actions;

export const selectList = (state: RootState) => state.users.list;

export const setListAsync = (accessToken: string, account_id: string): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/users/${account_id}`, {
    method: 'GET',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then((users) => {
      dispatch(setList({account_id, users}));
    })
    .catch((error) => handleError(dispatch, error))
}


export default usersSlice.reducer;
