export interface Device {
  device_id: string,
  account_id: string,
  serial_number: string,
  connection_id: string,
  device_date: string,
  model: string,
  firmware_version: string,
  system_status: string,
  alarm: string,
  temperature: string,
  flow_setting: string,
  fiO2_setting: string,
  fiO2_100: string,
  rox: string,
  spO2: string,
  pulse_rate: string,
  respiratory_rate: string,
  marker: string,
  seconds: string,
  hours: string,
  user_therapy_time: string,
  total_therapy_time: string,
  therapy_sessions: string,
  patient_count: string,
  timezone: string,
  name: string,
  modified: string,
  timestamp: string,
  online: boolean,
  confirmed_offline: boolean,
  device_time_drift: number,
  time_correction: number,
  alarm_codes: string,

  patient_name: string,
  patient_date_of_birth: string,
  patient_gender: string,
  patient_id: string,
  other_patient_id: string,
  clinical_notes: string,

  location: string,
  first_therapy_date: string,
  require_oxygen: string,

  doctor_user_id: string,
  staff_user_id: string,

  epoch: number,
}

export interface Devices {
  [key: string]: Device[]
}

export const SystemStatus : { [key: string]: string } = {
  '0': 'Provisioned',
  '1': 'Standby',
  '2': 'Therapy',
}

export interface Stat {
  min: number,
  max: number,
  avg: number,
  last: number,
}

export interface History {
  data: Device[],
  stats: {
    temperature: Stat,
    flow_setting: Stat,
    fiO2_setting: Stat,
    spO2: Stat,
    pulse_rate: Stat,
    respiratory_rate: Stat,
    rox: Stat,
  }
}

export interface Therapy {
  [therapy_session: string]: Device[],
}
