import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../app/store';
import {
  setLoggedIn
} from '../authorization/authorizationSlice';
import {getHeaders, handleError, handleResponse} from "../../utils/fetchUtils";

interface MeState {
  email: string,
  roles: string,
  name: string,
  account_id: string,
}

const initialState: MeState = {
  email: '',
  roles: '',
  name: '',
  account_id: '',
};

export const meSlice = createSlice({
  name: 'me',
  initialState,
  reducers: {
    setEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setName: (state, action: PayloadAction<string>) => {
      state.name = action.payload;
    },
    setRoles: (state, action: PayloadAction<string>) => {
      state.roles = action.payload;
    },
    setAccountId: (state, action: PayloadAction<string>) => {
      state.account_id = action.payload;
    },
  },
});

export const { setEmail, setName, setRoles, setAccountId } = meSlice.actions;

export const selectEmail = (state: RootState) => state.me.email;

export const selectName = (state: RootState) => state.me.name;

export const selectRoles = (state: RootState) => state.me.roles;

export const selectAccountId = (state: RootState) => state.me.account_id;

export const setUserProfileAsync = (accessToken: string): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/me`, {
    method: 'GET',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then((data) => {
      dispatch(setEmail(data.requestContext.authorizer.claims.email))
      dispatch(setName(data.requestContext.authorizer.claims.name))
      dispatch(setRoles(data.requestContext.authorizer.claims['custom:roles']))
      dispatch(setAccountId(data.requestContext.authorizer.claims['custom:account']))
    })
    .catch((error) => handleError(dispatch, error))
}

export default meSlice.reducer;
