import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AppThunk, RootState } from '../../../app/store';
import {Device, Devices} from "../../../domain/Device";
import {getHeaders, handleError, handleResponse} from "../../../utils/fetchUtils";

interface DevicesPayload {
  account_id: string,
  devices: Device[],
}


interface DevicesState {
  list: Devices,
}

const initialState: DevicesState = {
  list: {},
}

export const devicesSlice = createSlice({
  name: 'devices',
  initialState,
  reducers: {
    setList: (state, action: PayloadAction<DevicesPayload>) => {
      state.list[action.payload.account_id] = action.payload.devices;
    },
    deleteDevicesState: (state) => {
      state.list = {}
    },
  },
});

export const { setList, deleteDevicesState } = devicesSlice.actions

export const selectList = (state: RootState) => state.devices.list

export const setListAsync = (accessToken: string, account_id: string): AppThunk => dispatch => {
  const {apiUrl, headers} = getHeaders(accessToken)
  fetch(`${apiUrl}/devices/${account_id}?patient_name=not_exists`, {
    method: 'GET',
    headers: headers,
  }).then(response => handleResponse(dispatch, response))
    .then((devices) => {
      dispatch(setList({account_id, devices}));
    })
    .catch((error) => handleError(dispatch, error))
}


export default devicesSlice.reducer;
