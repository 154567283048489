import React from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectAccessToken} from "../authorization/authorizationSlice";
import {
  Button, Form, FormGroup, Input, Label,
  Modal, ModalBody, ModalFooter, ModalHeader,
} from "reactstrap";
import {
  closePopup, selectDeviceId,
  selectLocationInput,
  selectPopup,
  setLocationInput,
} from "./confirmDeviceOfflineSlice";
import {updateDeviceAsync} from "./updateDevice";

interface ConfirmDeviceOfflineProps {
  onSave: any,
  accountId: string
}

export function ConfirmDeviceOffline(props: ConfirmDeviceOfflineProps) {
  const account_id = props.accountId
  const device_id = useSelector(selectDeviceId)
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken)
  const popup = useSelector(selectPopup)
  const locationInput = useSelector(selectLocationInput)

  return (
    <Modal isOpen={popup} >
      <ModalHeader>Confirm device offline</ModalHeader>
      <ModalBody>
        <Form>
          <FormGroup>
            <Label for="location">Specify device location (optional)</Label>
            <Input
              name="location"
              id="location"
              placeholder="Location"
              value={locationInput}
              onChange={e => dispatch(setLocationInput(e.target.value))}
            />
          </FormGroup>
        </Form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={() => {
          dispatch(updateDeviceAsync(accessToken, account_id, device_id, {
            location: locationInput,
            confirmed_offline: true
          }))
          dispatch(updateDeviceAsync(accessToken, account_id, device_id, {
            patient_name: '',
            patient_date_of_birth: '',
            patient_gender: '',
            patient_id: '',
            other_patient_id: '',
            doctor_user_id: '',
            clinical_notes: '',
          }, 'remove'))
          props.onSave()
          dispatch(closePopup())
        }}>Confirm</Button>
        <Button color="secondary" onClick={() => dispatch(closePopup())}>Cancel</Button>
      </ModalFooter>
    </Modal>
  )
}
