import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {
  selectList,
  setListAsync,
} from './alarmsSlice';
import {selectAccessToken} from "../authorization/authorizationSlice";
import {Button, Spinner, Table} from "reactstrap";
import {Link, useNavigate} from "react-router-dom";
import {SystemStatus} from "../../domain/Device";
import styles from './Alarms.module.css'
import {AutoRefresh} from "../../components/AutoRefresh";
import {ConfirmDeviceOffline} from "../modals/ConfirmDeviceOffline";
import {confirmDeviceOfflinePopup} from "../modals/confirmDeviceOfflineSlice";
import {CorrectTimeDrift} from "../modals/CorrectTimeDrift";
import {correctTimeDriftPopup} from "../modals/correctTimeDriftSlice";
import {siteTitlePrefix} from "../../App";

interface AlarmsProps {
  accountId: string,
}

export function Alarms(props: AlarmsProps) {
  const account_id = props.accountId
  const devices = useSelector(selectList);
  const list = devices[account_id]
  const dispatch = useDispatch()
  const accessToken = useSelector(selectAccessToken)
  const navigate = useNavigate()
  const title = 'Alarms'
  useEffect(() => {
    document.title = siteTitlePrefix + title
    dispatch(setListAsync(accessToken, account_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return list == null ? (<Spinner animation="border" className="m-5"/>) : (
    <>
      <h1>{title}</h1>
      <AutoRefresh callback={() => dispatch(setListAsync(accessToken, account_id))} timeout={2000} className="float-end mt-3"/>
      <Table striped bordered hover>
      <thead>
        <tr>
          <th className={styles.alertHead}>
            <svg className="bi" width="20" height="20" fill="currentColor">
              <use xlinkHref="/bootstrap-icons.svg#bell"/>
            </svg>
          </th>
          <th className={styles.serialHead}>Serial number</th>
          <th className={styles.statusHead}>Status</th>
          <th className={styles.locationHead}>Location</th>
          <th className={styles.onlineHead}>Online</th>
          <th className={styles.alarmsHead}>Alarms</th>
        </tr>
      </thead>
      <tbody>
      {list.length ? list.map(device => (
        <tr key={device.device_id}>
          <td>
            {device.alarm !== '0' &&
              <svg className="bi" width="20" height="20" fill="currentColor">
                <use xlinkHref="/bootstrap-icons.svg#bell-fill"/>
              </svg>
              }
          </td>
          <td>
            <Link to={`/${device.patient_name ? 'patients' : 'devices'}/${account_id}/${device.device_id}/-`}>{device.serial_number || 'N/A'}</Link>
          </td>
          <td>{SystemStatus[device.system_status || '0']}</td>
          <td>{device.location}</td>
          <td className='text-center'>
            {device.online ? 'online': 'offline'}
            {!device.online &&
              <Button color="secondary" className="m-1 btn-sm"
                      onClick={() => dispatch(confirmDeviceOfflinePopup(device))}>Confirm</Button>}
          </td>
          <td>
            {device.alarm_codes.split(',').map((alarm, index) => (
              <div key={index}>{alarm}</div>
            ))}
            {device.alarm_codes.toLowerCase().includes('device time drifted') &&
              <Button color="secondary" className="m-1 btn-sm"
                      onClick={() => dispatch(correctTimeDriftPopup(device))}>Correct time drift</Button>}
          </td>
        </tr>
      )) : <tr>
        <td colSpan={7}>No Alarms</td>
      </tr>}
      </tbody>
      </Table>
      <ConfirmDeviceOffline accountId={account_id} onSave={() => dispatch(setListAsync(accessToken, account_id))}/>
      <CorrectTimeDrift accountId={account_id} onSave={() => dispatch(setListAsync(accessToken, account_id))}/>
    </>
  );
}
