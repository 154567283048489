import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import {Device} from "../../domain/Device";

interface RemovePatientSlice {
  popup: boolean,
  deviceId: string,
}

const initialState: RemovePatientSlice = {
  popup: false,
  deviceId: ''
}

export const removePatient = createSlice({
  name: 'RemovePatient',
  initialState,
  reducers: {
    removePatientPopup: (state, action: PayloadAction<Device>) => {
      state.popup = true
      state.deviceId = action.payload.device_id
    },
    closePopup: (state) => {
      state.popup = false
    },
  }
})

export const {
  removePatientPopup,
  closePopup,
} = removePatient.actions

export const selectPopup = (state: RootState) => state.removePatient.popup
export const selectDeviceId = (state: RootState) => state.removePatient.deviceId

export default removePatient.reducer;
