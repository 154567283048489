import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectDoctorList, selectList, selectStaffList, setListAsync, setUserListsAsync} from './pastPatientsSlice';
import {selectAccessToken} from "../../authorization/authorizationSlice";
import {Spinner, Table} from "reactstrap";
import {Link, useNavigate, useParams} from "react-router-dom";
import moment from "moment";


export function PastPatients() {
  const { account_id } = useParams() as any
  const patients = useSelector(selectList);
  const list = patients[account_id]
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken)
  const navigate = useNavigate()
  const staffList = useSelector(selectStaffList)
  const doctorList = useSelector(selectDoctorList)
  const title = 'Prior Patients'

  useEffect(() => {
      dispatch(setUserListsAsync(accessToken, account_id))
      dispatch(setListAsync(accessToken, account_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return list == null ? (<Spinner animation="border" className="m-5"/>) : (
    <>
      <h1>{title}</h1>
      <div className="tab-toggler">
        <button type="button" className="btn btn-light m-1"
                onClick={() => navigate(`/current/patients/${account_id}`)}>Current</button>
        <button type="button" className="btn btn-dark m-1"
                onClick={() => navigate(`/past/patients/${account_id}`)}>Prior</button>
      </div>
      <Table striped bordered hover>
      <thead>
        <tr>
          <th>Patient name</th>
          <th>First therapy date</th>
          <th>Last therapy session</th>
          <th>RT contact</th>
          <th>Referral doctor</th>
          <th>Clinical notes</th>
        </tr>
      </thead>
      <tbody>
      {list.map(patient => (
        <tr key={`${patient.device_id}-${patient.patient_count}`}>
          <td>
            <Link to={`/past/patients/${account_id}/${patient.device_id.padStart(8, '0')}.${patient.patient_count.padStart(6, '0')}`}>{patient.patient_name}</Link>
          </td>
          <td>{patient.first_therapy_date}</td>
          <td>{patient.therapy_sessions ? moment.utc(patient.therapy_sessions.split(',').pop()).local().format('LLL'): ''}</td>
          <td>
            {staffList.filter(item => item.user_id === patient.staff_user_id).map(item => item.name)}
          </td>
          <td>
            {doctorList.filter(item => item.user_id === patient.doctor_user_id).map(item => item.name)}
          </td>
          <td>{patient.clinical_notes}</td>
        </tr>
      ))}
      </tbody>
    </Table>
    </>
  );
}
