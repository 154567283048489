import React, {useEffect} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {selectAccessToken} from "../../authorization/authorizationSlice";
import {
  Breadcrumb, BreadcrumbItem, DropdownItem, DropdownMenu, DropdownToggle, Nav, Navbar,
  Spinner, UncontrolledDropdown,
} from "reactstrap";
import {useNavigate, useParams} from 'react-router-dom'
import {SystemStatus} from "../../../domain/Device";
import {siteTitlePrefix} from "../../../App";
import styles from './DeviceState.module.css'
import {DataElement, DataElementList} from "../../../components/DataElement";
import {
  selectDevice,
  selectDoctorList,
  selectStaffList,
  setPastDeviceAsync,
  setUserListsAsync
} from "./deviceStateSlice";
import {Device} from "../../../domain/Device";
import {DeviceChart} from "../chart/DeviceChart";


export function PastDeviceState() {
  const { account_id, patient_id } = useParams() as any
  const obj : Device = useSelector(selectDevice)
  const dispatch = useDispatch();
  const accessToken = useSelector(selectAccessToken)
  const device = useSelector(selectDevice)
  const title = `Prior device`
  const staffList = useSelector(selectStaffList)
  const doctorList = useSelector(selectDoctorList)
  const navigate = useNavigate()

  useEffect(() => {
    document.title = siteTitlePrefix + title
  }, []);

  useEffect(() => {
    dispatch(setUserListsAsync(accessToken, account_id))
    dispatch(setPastDeviceAsync(accessToken, account_id, patient_id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return obj == null ? (<Spinner animation="border" className="m-5"/>) : (
    <>
      <h1>{title}</h1>
      <Breadcrumb tag="nav">
        <BreadcrumbItem tag="a" href="/">Home</BreadcrumbItem>
        <BreadcrumbItem tag="a" href={`/past/devices/${account_id}`}>Prior devices</BreadcrumbItem>
        <BreadcrumbItem active tag="span">
          {patient_id}
        </BreadcrumbItem>
      </Breadcrumb>
      <div className={styles.patientState}>
        <Navbar className='float-end m-0 p-0'>
          <Nav>
            <UncontrolledDropdown nav inNavbar>
              <DropdownToggle nav caret>
                Actions
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem onClick={() => navigate(`/past/devices/${account_id}/${patient_id}/chart`)}>
                  View chart details
                </DropdownItem>
                <DropdownItem disabled>
                  View ROX chart
                </DropdownItem>
              </DropdownMenu>
            </UncontrolledDropdown>
          </Nav>
        </Navbar>

        <DataElement title='date' value={device.device_date}/>
        <DataElement title='model' value={device.model}/>
        <DataElement title='firmware version' value={device.firmware_version}/>
        <DataElement title='connectivity module' value={device.connection_id}/>
        {'timezone' in device && <DataElement title='timezone' value={device.timezone}/>}
        {'location' in device && <DataElement title='location' value={device.location}/>}
        {'patient_name' in device && <DataElement title='patient' value={device.patient_name}/>}
        <br/>
        <DataElement title='system status' value={SystemStatus[device.system_status]}/>
        <DataElementList title='alarm codes' value={device.alarm_codes}/>
        <DataElement title='treatment time' value={device.user_therapy_time}/>
        <DataElement title='total therapy time' value={device.total_therapy_time}/>
        <DataElement title='patient count' value={device.patient_count}/>
        <DataElement title='first therapy date' value={device.first_therapy_date}/>
        <DataElement title='require oxygen' value={device.require_oxygen}/>
        <br/>
        <DataElement title='date of birth' value={device.patient_date_of_birth}/>
        <DataElement title='gender' value={device.patient_gender}/>
        <DataElement title='patient id' value={device.patient_id}/>
        <DataElement title='other patient id' value={device.other_patient_id}/>
        <DataElement title='referral doctor' value={
          doctorList.filter(item => item.user_id === device.doctor_user_id).map(item => item.name)
        }/>
        <DataElement title='care contact' value={
          staffList.filter(item => item.user_id === device.staff_user_id).map(item => item.name)
        }/>
        <DataElement title='clinical_notes' value={device.clinical_notes}/>
      </div>
      <DeviceChart chartType='single' embedded={true}/>
    </>
  );
}
